import React, { Fragment, useContext, useEffect } from "react";
import classes from "./ProjectMap.module.css";
import Footer from "../Layout/Footer";
import ProjectCard from "./ProjectCard";
import ProjectContext from "../Store/ProjectContext";
import { TransitionGroup, CSSTransition } from "react-transition-group";

const ProjectMap = () => {
  const generateID = () => {
    return Math.random() / 1.5;
  };
  const ctx = useContext(ProjectContext);
  ctx.projects.map((project) => (project.key = generateID()));

  const projo = ctx.projects.map((project, index) => (
    <CSSTransition
      key={project.key}
      classNames="fade"
      // style={{ transition: `all ${index * 0.15}s  ease` }}
      timeout={500}
    >
      <div className={classes.outercontainer}>
        <div className={classes.container}>
          <ProjectCard
            key={project.id}
            id={index + 1}
            color={project.color}
            url={project.url}
            name={project.title}
            type={project.type}
            link={project.link}
          />
        </div>
      </div>
    </CSSTransition>
  ));
  return (
    <Fragment>
      <TransitionGroup component="div" className={classes.box}>
        {projo}
      </TransitionGroup>
    </Fragment>
  );
};

export default ProjectMap;
