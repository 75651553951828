import React from "react";
import Modal from "../UI/Modal";
import { NavLink } from "react-router-dom";
import liIcon from "../Assets/liIcon.svg";
import ghIcon from "../Assets/ghIcon.svg";
import classes from "./MobileMenu.module.css";
import lock from "../Assets/lock.svg";
import logo from "../Assets/logo.png";
import hamburger from "../Assets/mobmen.png";
import ProjectSelectorHeader from "../Projects/ProjectSelectorHeader";

const MobileMenu = (props) => {
  const mobmenHandler = () => {
    props.closemenu();
  };
  return (
    <Modal>
      <NavLink to="/login" className={classes.item2}>
        Login
      </NavLink>
      <img
        className={classes.hamburger}
        onClick={mobmenHandler}
        src={hamburger}
      />
      <div onClick={mobmenHandler} className={classes.logo}>
        {/* <img className={classes.rlogo} src={logo} /> */}
        {/* <img
          src="http://robbieis.me/images/misc/rawcon.png"
          className={classes.rlogo}
        /> */}
      </div>
      <div className={classes.mobileNav}>
        <ProjectSelectorHeader close={mobmenHandler} />
        {/* <NavLink onClick={mobmenHandler} to="/work" className={classes.item}>
          Projects
        </NavLink> */}
        <NavLink
          onClick={mobmenHandler}
          to="/approach"
          className={classes.item}
        >
          Approach
        </NavLink>
        <NavLink onClick={mobmenHandler} to="/about" className={classes.item}>
          About
        </NavLink>
      </div>
      <div className={classes.social}>
        <a
          href="https://www.linkedin.com/in/robert-bailey-71429886/"
          target="_blank"
        >
          <img className={classes.socialIcon} src={liIcon} />
        </a>
        <a href="https://github.com/LuoTheBuilder" target="_blank">
          <img className={classes.socialIcon} src={ghIcon} />
        </a>
      </div>
    </Modal>
  );
};

export default MobileMenu;
