import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import classes from "./Header.module.css";
import liIcon from "../Assets/liIcon.svg";
import ghIcon from "../Assets/ghIcon.svg";
import lock from "../Assets/lock.svg";
import MobileMenu from "./MobileMenu";
import hamburger from "../Assets/mobmen.png";
import ProjectSelectorHeader from "../Projects/ProjectSelectorHeader";
import logo from "../Assets/logo.png";

const Header = () => {
  const [mobmen, setmobmen] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const mobmenHandler = () => {
    setmobmen(true);
  };

  const mobMencloser = () => {
    setmobmen(false);
  };

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 100);
    setPrevScrollPos(currentScrollPos);
  };

  const navbarStyles = {
    transition: "top 0.6s",
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  return (
    <header style={{ ...navbarStyles, top: visible ? "0" : "-100px" }}>
      {mobmen && <MobileMenu closemenu={mobMencloser} />}
      <NavLink to="/work" className={classes.logo}>
        <img src={logo} className={classes.rlogo} />
        <div>
          Robbie Bailey
          <div className={classes.sub}>Creative & Content</div>
        </div>
      </NavLink>
      <div className={classes.title}></div>
      <div className={classes.nav}>
        <ProjectSelectorHeader />
        {/* <NavLink to="/work" className={classes.item}>
          Work
        </NavLink> */}
        <NavLink to="/approach" className={classes.item}>
          Approach
        </NavLink>
        <NavLink to="/about" className={classes.item}>
          About
        </NavLink>

        <a
          href="https://www.linkedin.com/in/robert-bailey-71429886/"
          target="_blank"
        >
          <img className={classes.socialIcon} src={liIcon} />
        </a>
        <a href="https://github.com/LuoTheBuilder" target="_blank">
          <img className={classes.socialIcon} src={ghIcon} />
        </a>
        <NavLink to="/login" className={classes.item}>
          <img
            className={classes.socialIcon}
            style={{ margin: 0 }}
            src={lock}
          />
        </NavLink>
      </div>
      {/* //MobileNav       */}
      <div className={classes.mobileNav}>
        <img
          className={classes.hamburger}
          onClick={mobmenHandler}
          src={hamburger}
        />
      </div>
      {/* <div className={classes.float}></div> */}
    </header>
  );
};

export default Header;
