import React from "react";
import classes from "./Social.module.css";
const Video = ({ src, poster }) => {
  return (
    <div className={classes.vidholder}>
      <div className={classes.vidinner}>
        <center>
          <video
            className={classes.video}
            style={
              {
                // border: "solid 5px #f3f3f3",
                // borderRadius: "20px",
                //   filter: "drop-shadow(0px 0px 2px #000)",
              }
            }
            poster={poster}
            controls
          >
            <source src={src} type="video/mp4" />
          </video>
        </center>
      </div>
    </div>
  );
};

export default Video;
