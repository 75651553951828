import React, { Fragment, useState, useEffect } from "react";
import Header from "../Layout/Header";
import classes from "./Approach.module.scss";
import { Fade } from "react-reveal";
import Tagcloudy from "../UI/Tagcloud";
import Helmet from "react-helmet";

const Approach = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Approach | Robbie Bailey</title>
      </Helmet>
      <Header />
      {/* Section 1 - hero */}
      <Fade>
        <div
          style={{
            backgroundImage: `url("http://robbieis.me/images/misc/backgroundtr.jpg")`,
          }}
        >
          <div className={classes.container}>
            <div className={classes.containerinner}>
              <div className={classes.titlebox}>
                <h1>At the Foundation of Brand Is Language.</h1>
                <h6>
                  Grab a coffee and I’ll show you that this means you can engage
                  in market research and identify meaningful opportunities that
                  really distinguish your brand from the competition.{" "}
                </h6>
                or,
                <br />
                <br />
                <a
                  target={"_blank"}
                  href="https://www.amazon.com/Language-Brands-Linguistic-Framework-Distinction/dp/1661927181/ref=sr_1_1?crid=ZLXVTZT852OR&keywords=the+language+of+brands+robert+bailey&qid=1649520639&sprefix=the+language+of+brands+robert+bailey%2Caps%2C140&sr=8-1"
                >
                  <button className={classes.herobutton}>Buy the Book</button>
                </a>
                <div class={classes.downArrow}></div>
                <img
                  className={classes.steam}
                  src="http://robbieis.me/images/misc/steam.gif"
                />
                <img
                  className={classes.coffee}
                  src="http://robbieis.me/images/misc/coffee.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </Fade>

      <div className={classes.contentintro}>
        <b>
          Creating an incredible brand means understanding how to make it
          informative, resonant, relevant, and inspiring. The best brands do
          this by tapping into ideas and concepts that hold significant weight
          in the eyes of their audience. This page covers some of the ways in
          which that can be done through the analysis of language. If you want
          to learn more about this and dive deeper, check out The Language of
          Brands, which provides more detailed examples and takes a deeper look
          at the theory behind this approach.
        </b>
      </div>
      <div className={classes.contentmain}>
        <p>
          A brand’s identity is not innate. It’s the product of carefully
          curated language (both visual and textual), positioned in such a way
          as to promote the values a business and its clients believe in. From
          web pages to advertisements, the language a brand uses tells its
          story. It helps the brand to communicate with its audience and deliver
          messages that are relevant, informative and resonant.
        </p>
        <p>
          Here, I am going to briefly cover a key framework that underlies my
          approach to creative and marketing. This framework is based on
          numerous linguistic and marketing theories. Most significantly this
          includes corpus linguistics, a method for developing empirical models
          of language and discourse through analysis of large collections of
          texts.
        </p>
        <p>
          Before jumping into the approach itself, it’s important to first cover
          a key assumption that it holds: the idea that meaning is unique to the
          individual. As an individual consumes a text, they create “units of
          meaning” by filtering what they see through mental frameworks based on
          their own experiences and beliefs. On a micro scale, these filters can
          be very distinct and dissimilar. However, on a macro scale these
          filters become increasingly uniform and familiar – evolving into an
          overarching “discourse”.
        </p>

        <p>
          What this means is that when dealing with large audiences, it is
          possible to zero in on generally accepted beliefs and ideas that
          appear consistently within their discourse. This leaves us with two
          questions: how do we first acquire this information and then how
          should we categorize it?
        </p>

        <h2>Understanding the Four Pillars</h2>
        <p>
          The four pillars are based on four key metrics that define brand
          success. This includes the ability for a brand to inform audiences of
          key information, generate emotional reactions that resonate, portray
          tangible situations that are relatable, and encourage action.
        </p>
      </div>

      <div className={classes.pillarsinner}>
        <Fade right>
          <div className={classes.rowouter}>
            <hr />
            <div className={classes.row}>
              <div className={classes.number}>1</div>
              <div>
                <div className={classes.topmod}>The Bare Bones.</div>
                <div className={classes.header}>Inform</div>
                <div>By appealing to discourse convention.</div>
              </div>
            </div>
          </div>
        </Fade>
        <Fade right>
          <div className={classes.rowouter}>
            <hr />
            <div className={classes.row}>
              <div className={classes.number}>2</div>
              <div>
                <div className={classes.topmod}>The Abstract.</div>
                <div className={classes.header}>Resonate</div>
                <div>By understanding social convention.</div>
              </div>
            </div>
          </div>
        </Fade>
        <Fade right>
          <div className={classes.rowouter}>
            <hr />
            <div className={classes.row}>
              <div className={classes.number}>3</div>
              <div>
                <div className={classes.topmod}>The Tangible.</div>
                <div className={classes.header}>Relate</div>
                <div>With messaging and SEO.</div>
              </div>
            </div>
          </div>
        </Fade>
        <Fade right>
          <div className={classes.rowouter}>
            <hr />
            <div className={classes.row}>
              <div className={classes.number}>4</div>
              <div>
                <div className={classes.topmod}>The Distinct.</div>
                <div className={classes.header}>Inspire</div>
                <div>Through creating unique connections.</div>
              </div>
            </div>
          </div>
        </Fade>
      </div>

      <div className={classes.contentmain}>
        <p>
          The four pillars help to break down discourse into manageable chunks
          that contribute to the building and maintenance of creative and brand.
          They not only describe the elements of discourse that should be
          focused on, but also how they can be applied and to what effect. For
          example, a web hosting technology brand needs to be able to relay key
          technical information to their audience using creative that encourages
          them to feel excited about the product, recognize situations that they
          have experienced before, and ultimately gets them to purchase the
          product.
        </p>

        <p>
          The goal of the four pillars is to inspire audiences to engage in
          actions that are beneficial to the brand. This can be as simple as
          clicking a button or as complex as advocating for the brand and its
          objectives with friends and family. Regardless of the goal, the key to
          success is being able to layer each of the four pillars to create a
          clear pathway for individual meaning creation.
        </p>

        <h2>Developing a Lexicon</h2>
        <p>
          The pillars themselves are not actionable. As such, they require the
          development of a lexicon. The words we choose carry with them meaning
          and weight. To make something better is different than to make
          something the best; to optimize is different than to improve. As
          brands come to realize their own identity, so too must they realize
          their own language: their own lexicon.
        </p>

        <p>
          The words that form a lexicon show tone and style, but they also
          embody the story a brand is trying to tell and the journey a user will
          embark on. A lexicon also tells us a lot about the creator of a text,
          including subconscious beliefs and biases.
        </p>
        <p>
          In this way, genuine opportunities to connect with an audience can be
          identified through understanding the differences between the lexicon
          that makes up the discourse of other brands, and the lexicon that
          makes up the discourse of audiences. Clear, segmented lexicons make it
          possible to more accurately promote the right values and the right
          information at the right time.
        </p>

        <p>
          An example of two lexicons can be seen below, taken from the
          relatively niche backpacking travel industry. First is a small,
          curated lexicon derived from audiences, second is one derived from
          other brands within the industry.
        </p>
      </div>
      <div className={classes.pillarsinnerflex}>
        <Tagcloudy
          box=".lexicon1"
          width="150"
          words={[
            "Travel",
            "Asia",
            "Planning",
            "Island",
            "Bangkok",
            "Need",
            "Backpacking",
            "Eat",
            "Mountains",
          ]}
        />
        <Tagcloudy
          box=".lexicon2"
          width="150"
          words={[
            "Budget",
            "Book",
            "Hotel",
            "Recommendation",
            "eat",
            "Modern",
            "English",
            "Travel",
            "Backpackers",
          ]}
        />

        <div className={classes.box}>
          <h3> Audience</h3>
          <span className="lexicon1"></span>
        </div>
        <div className={classes.box}>
          <h3>Industry</h3>
          <span className="lexicon2"></span>
        </div>
      </div>
      <div className={classes.contentmain}>
        <p>
          The differences between the two are immediately clear. The most
          distinguishing being how the industry focuses on more practical
          aspects of travel (budget, book, hotel, English), while the audience
          dials in primarily on experiential aspects (mountains, island, Asia,
          Bangkok). The difference here presents an opportunity to better
          resonate and relate to the audience, building relationships between
          concepts that push toward the ultimate goal of inspiring the audience
          to action.{" "}
        </p>
        <p>
          Of course, it is still important to capture the industry’s lexicon.
          The word “budget” is likely an important SEO term and fits within a
          discourse convention that has been built up over years. It would be a
          mistake to completely follow the audience lexicon and ignore that of
          the industry. Rather, the opportunity here is to gradually modify the
          industry lexicon within the brand and move toward that of the audience
          over time.
        </p>
        {/* <h2>The Outcome</h2>
        <p>
          Various industries have various degrees of separation from their
          audience. Sometimes it’s surprising how much the industry and the
          audience differ in their discourse. Other times, it’s surprising how
          little they differ. Ultimately, however, the goal of this approach is
          to bring the two together in a way that appeals to both convention and
          resonance. Usually the balance is 75% audience, 25% industry.
        </p> */}
      </div>
      <div className={classes.book}>
        <h2>Interested in learning more?</h2>
        <div className={classes.bookholder}>
          <div className={classes.box}>
            <h3>
              The Language of Brands: A Linguistic Framework for Creating Brand
              Distinction
            </h3>
            <p>
              There’s a new rule in marketing: brands must speak to consumers
              using their language. The Language of Brands takes you and your
              brand on a journey, one that will ultimately help you inspire
              consumers to take action and advocate for your products without a
              second thought.
            </p>
            <p>
              This book is about how the largest and most successful businesses
              use language to connect with consumers. It’s about how the words
              marketers use do more than just share simple messages. And why
              content has become such a central part of the marketing machine.
            </p>
            <p>
              The Language of Brands looks at businesses that have successfully
              mastered their language to great effect. The brand that conquered
              Christmas and became a part of December canon, the brand whose
              very name instantly brings with it a sense of motivation and
              potential, and the brand whose language did more for the diamond
              industry than a sparkling stone ever could.
            </p>
            <p>
              The Language of Brands reveals why when we look at billboards and
              webpages - and whatever other channels businesses market to us
              through - we read what we read, see what we see, and feel what we
              feel.
            </p>
            <ul>
              <li>
                Learn how to market your brand to consumers using their
                language.
              </li>

              <li>
                Develop clear frameworks for brand distinction within an
                industry or topic.
              </li>
              <li>
                Explore bleeding-edge techniques for language analysis across
                entire industries with minimal lift.
              </li>
              <li>
                Learn simple, often neglected methodologies for dominating in
                search engine results and SEO.
              </li>
              <li>
                See real-life examples of what makes branding powerhouses like
                Apple, McDonald’s and Nike different than their competitors.
              </li>
              <li>
                Understand the metrics that will help you get buy-in for
                instigating brand change within your organization.
              </li>
            </ul>
          </div>
          <div className={classes.box}>
            <div className={classes.boxinner}>
              <img
                className={classes.bookimg}
                src="http://robbieis.me/images/misc/booktra.png"
              />
              <center>
                <a
                  target={"_blank"}
                  href="https://www.amazon.com/Language-Brands-Linguistic-Framework-Distinction/dp/1661927181/ref=sr_1_1?crid=ZLXVTZT852OR&keywords=the+language+of+brands+robert+bailey&qid=1649520639&sprefix=the+language+of+brands+robert+bailey%2Caps%2C140&sr=8-1"
                >
                  <button className={classes.bottombutton}>Buy the Book</button>
                </a>
              </center>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Approach;
