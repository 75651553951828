import React, { Fragment } from "react";
import classes from "./Footer.module.css";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer>
      <div className={classes.copyright}>© {year} Robbie Bailey</div>
    </footer>
  );
};

export default Footer;
