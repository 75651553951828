import React from "react";
import { Link } from "react-router-dom";
import classes from "./ProjectCard.module.css";

const ProjectCard = (props) => {
  return (
    <Link to={props.link}>
      <div className={classes.container}>
        <img className={classes.image} src={props.url} />
        <div className={classes.middle}>
          <div className={classes.text}>
            {props.name}
            <div
              style={{ backgroundColor: `${props.color}` }}
              className={classes.divide}
            />

            <span style={{ color: `${props.color}` }}>{props.type}</span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ProjectCard;
