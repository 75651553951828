import React, { Fragment } from "react";
import Header from "../Layout/Header";
// import Hero from "./Pages/Hero";
import ProjectBox from "../Projects/ProjectBox";

const Work = () => {
  return (
    <Fragment>
      <Header />
      <ProjectBox />
    </Fragment>
  );
};

export default Work;
