import React, { Fragment } from "react";
import Footer from "./Layout/Footer";
import RouterPage from "./Pages/RouterPage";
import { ProjectContextProvider } from "./Store/ProjectContext";

function App() {
  return (
    <ProjectContextProvider>
      <RouterPage />
      <Footer />
    </ProjectContextProvider>
  );
}

export default App;
