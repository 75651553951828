export const FETCH_ALL = "FETCH_ALL";
export const FETCH = "FETCH";
export const CREATE = "CREATE";
export const UPDATE = "UPDATE";
export const DELETE = "DELETE";
export const ERROR = "ERROR";
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const ADDTOMENU = "ADDTOMENU";
export const DELETEITEM = "DELETEITEM";
export const GETPREFS = "GETPREFS";
export const SAVECREDS = "SAVECREDS";
export const UPDATEPREFS = "UPDATEPREFS";
