import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import Work from "./Work";
import About from "./About";
import Login from "./login";
import ProjectPage from "../ProjectPages/ProjectPage";
import Choice from "./Choice";
import ScrollToTop from "../UI/ScrollToTop";
import Approach from "./Approach";
import Test from "./Test";
const RouterPage = () => {
  function mapStyles(styles) {
    return {
      opacity: styles.opacity,
      transform: `scale(${styles.scale})`,
    };
  }

  return (
    <Fragment>
      <ScrollToTop />
      <Switch>
        <Route path="/" exact>
          <Work />
        </Route>
        <Route path="/work" exact>
          <Work />
        </Route>

        <Route path="/work/:projectId">
          <ProjectPage />
        </Route>
        <Route path="/about" exact>
          <About />
        </Route>
        <Route path="/approach" exact>
          <Approach />
        </Route>
        <Route path="/login" exact>
          <Login />
        </Route>
      </Switch>
    </Fragment>
  );
};

export default RouterPage;
