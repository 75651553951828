import React from "react";
import classes from "../ProjectPage.module.css";
import quoteimg from "../../Assets/quote.png";
const Quote = ({ quote, color }) => {
  return (
    <div className={classes.quote} style={{ borderLeft: `4px solid ${color}` }}>
      <img src={quoteimg} className={classes.quoteimage} />
      {quote}
    </div>
  );
};

export default Quote;
