import React, { useContext, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import ProjectContext from "../Store/ProjectContext";
import classes from "../Layout/Header.module.css";

const ProjectSelectorHeader = ({ close }) => {
  const ctx = useContext(ProjectContext);

  const history = useHistory();
  useEffect(() => {
    ctx.updateProjects("all");
  }, []);

  const printHandler = () => {
    ctx.updateProjects("print");
    close();
  };

  const tvcHandler = async () => {
    await history.push("/work");
    ctx.updateProjects("TVC");

    close();
  };
  const codeHandler = async () => {
    await history.push("/work");
    ctx.updateProjects("digital");

    close();
  };
  const awardHandler = async () => {
    await history.push("/work");
    ctx.updateProjects("award");

    close();
  };
  const creativeHandler = async () => {
    await history.push("/work");
    ctx.updateProjects("creative");

    close();
  };
  return (
    <>
      {/* <button onClick={allHandler}>All</button> */}
      <Link className={classes.item} to="/work" onClick={creativeHandler}>
        Selects
      </Link>
      <Link className={classes.item} to="/work" onClick={tvcHandler}>
        TVC
      </Link>
      <Link className={classes.item} to="/work" onClick={codeHandler}>
        Digital
      </Link>
      {/* <NavLink className={classes.item} to="/work" onClick={printHandler}>
        Print
      </NavLink> */}
      <NavLink className={classes.item} to="/work" onClick={awardHandler}>
        Award-Winning{" "}
      </NavLink>
    </>
  );
};

export default ProjectSelectorHeader;
