import React from "react";

const AwardList = ({ name, body }) => {
  return (
    <li style={{ textAlign: "left", fontSize: "14px" }}>
      <b>{body}</b>: <span style={{ fontWeight: 200 }}>{name}</span>
    </li>
  );
};

export default AwardList;
