import React from "react";

const MetricList = ({ name, result }) => {
  return (
    <li style={{ textAlign: "left", fontSize: "14px" }}>
      <b>{name} </b> <span style={{ fontWeight: 200 }}>{result}</span>
    </li>
  );
};

export default MetricList;
