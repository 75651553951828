import React, { useContext, Fragment, useState } from "react";
import Classes from "./ProjectPage.module.css";
import { useParams } from "react-router-dom";
import projects from "../Project";
import Quote from "./ProjectElements/Quote";
import Social from "./ProjectElements/Social";
import ExternalLink from "./ProjectElements/ExternalLink";
import Header from "../Layout/Header";
import AwardList from "./ProjectElements/AwardList";
import MetricList from "./ProjectElements/MetricList";
import Helmet from "react-helmet";
import More from "../UI/more";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.css";

const ProjectPage = () => {
  const params = useParams();

  const projData = projects.filter((project) =>
    project.link.includes(params.projectId)
  );

  return (
    <Fragment>
      <Helmet>
        <title>{projData[0].title} | Robbie Bailey</title>
        <meta name="description" content={projData[0].description} />
        <meta property="og:title" content={projData[0].title} />
        <meta property="og:image" content={projData[0].fullwidth} />
      </Helmet>
      <Header />

      <div className={Classes.titlebox}>
        <h3 style={{ color: `${projData[0].color}` }}>{projData[0].brand}</h3>

        <h1>{projData[0].title}</h1>

        <h6>{projData[0].subtitle}</h6>
        <h5 className={Classes.info}>
          {projData[0].date} // {projData[0].deliverables} // {projData[0].role}
        </h5>

        <p className={Classes.description}>
          <span>{projData[0].description}</span>
          <br />
          {projData[0].descriptioncont}
          {projData[0].metrics && (
            <>
              <div className={Classes.pawardtitle}>Results</div>
              {projData[0].metrics.map((metric) => (
                <MetricList name={metric.name} result={metric.result} />
              ))}
            </>
          )}
          {projData[0].awards && (
            <>
              <div className={Classes.pawardtitle}>Awards</div>
              {projData[0].awards.map((award) => (
                <AwardList name={award.name} body={award.body} />
              ))}
            </>
          )}
        </p>
        {projData[0].code && projData[0].codeb}
        {/* <div className={Classes.awardHolder}>
          {projData[0].awards
            ? projData[0].awards.map((award) => (
                <Award awardBody={award.body} awardName={award.name} />
              ))
            : projData[0].metrics && !projData[0].quotes
            ? projData[0].metrics.map((metric) => (
                <Metric name={metric.name} result={metric.result} />
              ))
            : null}
        </div> */}
        {/* {projData[0].externallink} */}
      </div>
      {/* {projData[0].fullwidth && (
        <img className={Classes.fullwidthtop} src={projData[0].fullwidth} />
      )} */}

      {projData[0].fullwidth && (
        <div
          className={Classes.fullwidthtop}
          style={{
            backgroundImage: `url(${projData[0].fullwidth})`,
          }}
        />
      )}
      {/* {projData[0].metrics && projData[0].fullwidth && projData[0].quotes ? (
        <div className={Classes.metBelowImage}>
          <div className={Classes.leftmet}>
            {projData[0].metrics.map((metric) => (
              <Metric name={metric.name} result={metric.result} />
            ))}
          </div>
          <div className={Classes.rightQuote}>
            <img src={quote} className={Classes.quoto} />
            {projData[0].quotes.map((quote) => (
              <Quote quote={quote} />
            ))}
          </div>
        </div>
      ) : projData[0].metrics &&
        projData[0].fullwidth &&
        !projData[0].quotes &&
        projData[0].awards ? (
        <div className={Classes.metnoquote}>
          {projData[0].metrics.map((metric) => (
            <Metric name={metric.name} result={metric.result} />
          ))}
        </div>
      ) : (
        <></>
      )} */}

      <center>
        <img className={Classes.partwidth} src={projData[0].imgabove} />
      </center>

      <div
        className={Classes.videofull}
        style={{ BackgroundColor: `${projData[0].color}` }}
      >
        <div className={Classes.creative}>
          {projData[0].coretitle && (
            <div className={Classes.exp}>
              <h7 className="lines">{projData[0].coretitle}</h7>
              <p>{projData[0].coreexp}</p>
            </div>
          )}

          {projData[0].vid1 && (
            <Carousel data-bs-theme="dark">
              {projData[0].vid1 && (
                <Carousel.Item interval={100000000}>
                  <iframe
                    className={Classes.ytvid}
                    src={projData[0].vid1}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <Carousel.Caption>{projData[0].vid1desc}</Carousel.Caption>
                </Carousel.Item>
              )}
              {projData[0].vid2 && (
                <Carousel.Item interval={100000000}>
                  <iframe
                    className={Classes.ytvid}
                    src={projData[0].vid2}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <Carousel.Caption>{projData[0].vid2desc}</Carousel.Caption>
                </Carousel.Item>
              )}
              {projData[0].vid3 && (
                <Carousel.Item interval={100000000}>
                  <iframe
                    className={Classes.ytvid}
                    src={projData[0].vid3}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                  <Carousel.Caption>{projData[0].vid3desc}</Carousel.Caption>
                </Carousel.Item>
              )}
            </Carousel>
          )}
          {/* {projData[0].externallink && (
          <center>
            <ExternalLink data={projData[0]} />
          </center>
        )} */}
          {projData[0].block}
        </div>
      </div>
      <div className={Classes.creative}>
        {projData[0].block2}
        {projData[0].extensions && (
          <div className={Classes.exp}>
            <h7 className="lines">Extensions</h7>
            <p>{projData[0].extensions}</p>
          </div>
        )}
        <img className={Classes.fullwidth} src={projData[0].img1} />
        <img className={Classes.fullwidth} src={projData[0].img2} />
        <img className={Classes.fullwidth} src={projData[0].img3} />
        <img className={Classes.fullwidth} src={projData[0].img4} />
        <img className={Classes.fullwidth} src={projData[0].img5} />
        <img className={Classes.fullwidth} src={projData[0].img6} />
        <img className={Classes.fullwidth} src={projData[0].img7} />
        {projData[0].block3}

        {projData[0].externallink && projData[0].social && <div></div>}
        {projData[0].social && <Social data={projData[0].social} />}
        {projData[0].quotes && (
          <div className={Classes.midQuote}>
            {projData[0].quotes.map((quote) => (
              <Quote quote={quote} color={projData[0].color} />
            ))}
          </div>
        )}
      </div>
      {projData[0].fullwidth2 && (
        <div
          className={Classes.fullwidthtop}
          style={{
            marginTop: 200,
            backgroundImage: `url(${projData[0].fullwidth2})`,
            height: 900,
            width: "100%",
            backgroundAttachment: "fixed",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      )}
      {/* <More /> */}
    </Fragment>
  );
};

export default ProjectPage;

// {projData[0].Metric1} {projData[0].Metric1perf}
//         {projData[0].Metric2} {projData[0].Metric2perf}
