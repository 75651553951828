import React, { Fragment } from "react";
import { ProjectContextProvider } from "../Store/ProjectContext";
import ProjectMap from "./ProjectMap";
import ProjectSelector from "./ProjectSelector";
import Helmet from "react-helmet";

const ProjectBox = () => {
  return (
    // <ProjectContextProvider>
    <>
      <Helmet>
        <title>My Work | Robbie Bailey</title>
      </Helmet>
      {/* <ProjectSelector /> */}
      <ProjectMap />
    </>
    // </ProjectContextProvider>
  );
};

export default ProjectBox;
