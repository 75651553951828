import React, { Fragment } from "react";
import classes from "./ProjectPages/Projects.module.css";
import ExampleCarouselImage from "./Assets/ghIcon.svg";
import webicon from "./Assets/webicon.svg";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.css";

const projects = [
  {
    key: 1,
    order: 1,
    brand: "The Amazon Business American Express Card",
    title: "With your business in mind",
    type: "Campaign",
    url: "https://robbieis.me/images/covers/SBCC2.png",
    link: "/work/your-business-your-goals",
    deliverables: "Campaign",
    prType: "all creative digital select",
    role: "Head of Creative",
    subtitle: "A business Card designed to fit every business's unique needs.",
    color: "#1d98ff",
    date: "2024",
    description:
      "Almost 90 percent of business owners want to know that everything business related is just somehow secure and going as expected. They want to see consistent growth, unrestricted by problems with cash flow or an inability to organize their finances effectively.  ",
    descriptioncont: (
      <>
        <p>
          Yet these same businesses often face unexpected challenges, requiring
          them to pivot quickly while also maintaining clear and organized
          visibility into their organization’s financial health. This lack of
          stability has only been compounded by market volatility and economic
          uncertainty over the last few years.
        </p>
        <p>
          The Amazon Business Card aims to help by providing the flexibility to
          pivot financial strategy on a case-by-case basis, through allowing
          them to choose between payment terms and rewards. At the same time, it
          also helps business owners to maintain visibility into their financial
          health with payments analytics connected to their Amazon Business
          account.
        </p>
        <p>
          By providing the right mix of the right benefits – with the ability to
          choose which benefits to use when – the Amazon Business Card is
          designed to help business owners succeed in the complexity of their
          every day.
        </p>
      </>
    ),
    // metrics: [
    //   { name: "Reach", result: "10M+" },
    //   { name: "Rollout", result: "8 channels" },
    // ],
    // awards: [
    //   {
    //     body: "The Telly Awards",
    //     name: "Silver - Regional TV, 2022",
    //   },
    // ],
    vid1: "https://www.youtube.com/embed/JaMriG4P4Fo?si=oU12u7FC9aVOU7G5",
    // vid1desc: "sdfsdfsdf3",
    vid2: "https://www.youtube.com/embed/AaeyKPSjLm8?si=2cW4JHA52OKRvd3s",
    // vid2desc: "sdfsdfsdf3",
    vid3: "https://www.youtube.com/embed/yb9CxgiBpuw?si=S5syM8SPBRcROYH3",
    // vid3desc: "sdfsdfsdf3",

    fullwidth:
      "https://robbieis.me/images/projects/designedwith/fullwidth6.jpg",
    fullwidth2:
      "https://robbieis.me/images/projects/designedwith/fullwidth3.jpg",

    coretitle: "Video",
    coreexp:
      "At the center of the campaign was 3x :15s ad spots, focused on the Amazon Business Card holder, Megan Savely and her business Frost Me Sweet. Each spot focused on specific product benefits pulled from audience research, with the overall shoot providing creative for use across all channels and placements.  ",
    extensions:
      "Designed with your business in mind was rolled out across channels to meet the customer throughout their journey. Whether that was on the product landing page, through email campaigns, or while watching Fire TV. ",
    img7: "https://robbieis.me/images/projects/designedwith/dig4.png",
    img1: "https://robbieis.me/images/projects/designedwith/dig1.png",
    img3: "https://robbieis.me/images/projects/designedwith/dig2.png",
    img5: "https://robbieis.me/images/projects/designedwith/dig3.png",
    img4: "https://robbieis.me/images/projects/designedwith/dig2.5.png",
    img2: "https://robbieis.me/images/projects/designedwith/dig6.png",
    img6: "https://robbieis.me/images/projects/designedwith/dig5.png",
  },
  {
    key: 1,
    order: 1,
    brand: "Amazon Lending",
    title: "Your goals. Financed.",
    type: "Campaign",
    url: "https://robbieis.me/images/covers/YGF32.png",
    link: "/work/your-goal-financed",
    deliverables: "Campaign",
    prType: "all creative digital award select",
    role: "Head of Creative",
    subtitle: "Because financing business growth starts by working together.",
    color: "#178397",
    date: "2023",
    description:
      "For 70 percent of Amazon sellers, growth is the key to success. Yet growth comes with uncertainty. Uncertainty around stock levels, product lines, marketing audience, and more. So how can an Amazon seller be confident their growth strategy is the right one? ",
    descriptioncont: (
      <>
        <p>
          Amazon Lending understands the unique needs and situations that Amazon
          sellers go through and, combined with other Amazon integrations, is
          not only able to offer sellers financing that alleviates risk around
          growth decisions, but also tools and data that help sellers make sure
          those decisions are informed.
        </p>
        <p>
          From the entrepreneurial geniuses leveraging financing to run product
          testing, to the laid-back Californians using those funds to drive
          growth during periods of economic uncertainty, Amazon Lending is here
          to help sellers get their goals financed.
        </p>
      </>
    ),

    vid1: "https://player.vimeo.com/video/883603928?h=3ed106973a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    // vid1desc: "P&J Trading",
    vid2: "https://player.vimeo.com/video/928835701?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    // vid3: "https://www.youtube.com/embed/gYNA7aKorgk?si=6ug6eS9rK08pvI2c",
    fullwidth:
      "https://robbieis.me/images/projects/yourgoalsfinanced/fullwidth5.jpg",
    fullwidth2:
      "https://robbieis.me/images/projects/yourgoalsfinanced/fullwidth2.jpg",

    metrics: [
      { name: "Application Interest", result: "↑235%" },
      { name: "Channel expansion", result: "5 new channels" },
      { name: "International rollout", result: "5 countries" },
    ],
    awards: [
      {
        body: "The Telly Awards",
        name: "Silver - Customer testimonial, 2024",
      },
    ],
    // social: {
    //   bio: "The Amazon Lending campaign captured audiences across Twitter, Facebook, Instagram and LinkedIn, with posts that linked to an article about the seller at the core of each story. ",

    //   pics: [
    //     {
    //       pic: "https://robbieis.me/images/projects/yourgoalsfinanced/social1.jpg",
    //     },
    //     {
    //       pic: "https://robbieis.me/images/projects/yourgoalsfinanced/social2.jpg",
    //     },
    //   ],
    // },
    block3: (
      <iframe
        style={{ borderRadius: "12px", marginBottom: 25 }}
        src="https://embed.podcasts.apple.com/us/podcast/detara-finds-funding-for-her-business/id1622261099?i=1000600727974"
        width="100%"
        height="232"
        frameBorder="0"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
      ></iframe>
    ),
    coretitle: "Video",
    coreexp:
      "Multiple sellers. Multiple stories. Creating social proof was vital to the success of the campaign. And what better social proof than hearing from the sellers themselves.",
    extensions:
      "Your goals. Financed. launched with multiple partner channels across Amazon, including an About Amazon featured article, a custom landing page, email, direct mail, and a podcast episode.",
    img7: "https://robbieis.me/images/projects/yourgoalsfinanced/dig2.png",
    img2: "https://robbieis.me/images/projects/yourgoalsfinanced/dig1.png",
    img3: "https://robbieis.me/images/projects/yourgoalsfinanced/dig3.png",
    img4: "https://robbieis.me/images/projects/yourgoalsfinanced/dig4.png",
    img5: "https://robbieis.me/images/projects/yourgoalsfinanced/dig5.png",
    img6: "https://robbieis.me/images/projects/yourgoalsfinanced/dig6.png",
  },

  {
    key: 1,
    order: 2,
    brand: "Amazon Business Pay by Invoice",
    title: "Save Time. Stay Organized.",
    type: "Campaign",
    url: "https://robbieis.me/images/covers/stso2.png",
    link: "/work/stso",
    deliverables: "Campaign",
    prType: "all creative digital award select",
    role: "Head of Creative",
    subtitle: "Because businesses deserve to manage invoices confidently.",
    color: "#827af0",
    date: "2024",
    description:
      "When it comes to payments, 90 percent of businesses care about more than just making them on time. Payments means staying confident when it comes to managing cash flow, maintaining supplier relationships, and organizing invoices. ",
    descriptioncont: (
      <>
        <p>
          Pay by Invoice is an invoicing and net terms solution designed to
          address these needs. Integrated directly into the Amazon purchasing
          experience, it helps businesses track and organize expenses, while
          also providing net terms to keep cash flow healthy. Yet in a market
          already saturated with numerous payment methods – including over 650
          credit cards in the US alone – trying to change payment habits can be
          tricky.
        </p>
        <p>
          Save time. Stay organized. was concepted around the idea that
          businesses want their payment method to give them a feeling of comfort
          and simplicity and help them save time. So when it comes to making the
          switch, they can stay confident that they will be managing payments
          right.
        </p>
      </>
    ),

    vid1: "https://player.vimeo.com/video/952388373?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    fullwidth: "https://robbieis.me/images/projects/stso/fullwidth.jpg",
    fullwidth2: "https://robbieis.me/images/projects/stso/fullwidth4.jpg",

    // metrics: [
    //   { name: "Email CTA", result: ">30%" },
    //   { name: "Run Time", result: "4 months" },
    // ],
    awards: [
      {
        body: "The Telly Awards",
        name: "Silver - Customer testimonial, 2022",
      },
    ],
    social: {
      bio: " ",
      vids: [
        {
          vid: "https://robbieis.me/images/projects/stso/social1.mp4",
          poster: "https://robbieis.me/images/projects/stso/poster.png",
        },
        {
          vid: "https://robbieis.me/images/projects/stso/social2.mp4",
          poster: "https://robbieis.me/images/projects/stso/poster2.png",
        },
      ],
    },

    coretitle: "Video",
    // coreexp:
    //   "Core to the campaign was a customer testimonial, providing social proof ",
    extensions: " ",
    img1: "https://robbieis.me/images/projects/stso/dig1.png",
    img2: "https://robbieis.me/images/projects/stso/dig2.png",
    img3: "https://robbieis.me/images/projects/stso/dig3.png",
    img4: "https://robbieis.me/images/projects/stso/dig4.png",
  },

  {
    key: 1,
    order: 1,
    brand: "Rocket Mortgage",
    title: "Home. Made.",
    role: "Creative Strategy & ACD",
    subtitle: "The story of how home is more than just four walls.",
    type: "Podcast",
    url: "https://robbieis.me/images/covers/HomeMade.jpg",
    link: "/work/home-made-podcast",
    prType: "all creative award digital select",
    deliverables: "Podcast, Social",
    date: "2021",
    metrics: [
      { name: "Documentary Chart - Apple Podcasts", result: "Peaked at #10" },
      { name: "Total Episode Downloads", result: "200k+" },
      { name: "Audience Retention", result: "85%" },
    ],
    quotes: [
      "This show introduces a whole new sound to branded podcasts.",
      "Not what I expected from a mortgage company. Excellent story! Found this because I love Stephanie Foo’s work. Definitely want the next episode and definitely recommend this podcast!",
      "I listened out of curiosity, and it definitely hooked me! Highly recommend you check this podcast out!",
    ],
    awards: [
      { body: "The Shorty Awards", name: "Winner - Branded Podcast, 2022" },
      {
        body: "The Shorty Awards",
        name: "Audience Honor - Branded Podcast, 2022",
      },
      { body: "The Signal Awards", name: "Gold - Lifestyle, 2022" },
      { body: "The Signal Awards", name: "Bronze - Craft-Best Host, 2022" },

      { body: "IHAF", name: "Gold, 2022" },
      { body: "The Muse Awards", name: "Gold - Branded Podcast, 2022" },

      {
        body: "The D Show",
        name: "Silver - Best of Audio Marketing, 2022",
      },
      {
        body: "The Telly Awards",
        name: "Bronze - Branded Podcast, 2022",
      },
      {
        body: "Digiday Content Marketing Awards",
        name: "Finalist - Best Branded Podcast, 2022",
      },
      {
        body: "New York Festivals Advertising Awards",
        name: "Short List - Audio/Radio: Best Use Podcasts, 2022",
      },
      { body: "Apple Podcasts", name: "New & Noteworthy, 2021" },
    ],
    description:
      "Mortgages have become a defining part of the American dream. A part so pervasive that more than 60% of Americans have one and more than 11,000 businesses offer them. So how can one mortgage company stand out?",
    descriptioncont: (
      <>
        <p>
          Introducing Home. Made., A 10-part documentary podcast series that
          redefines what a branded podcast can be, by exploring a concept that
          connects us all: home. Home. Made. showcases stories that focus on the
          diversity of home in America and that highlight how it’s more than
          just 4 walls. By doing this, it taps into what makes a mortgage
          meaningful and attaches that meaning to the brand Rocket Mortgage.
        </p>
      </>
    ),
    fullwidth: "https://robbieis.me/images/projects/homemade/fullwidth2.jpg",
    fullwidth2: "https://robbieis.me/images/projects/homemade/fullwidth.jpg",
    externallink: {
      type: "hear",
      link: "https://podcasts.apple.com/us/podcast/home-made/id1557484598",
    },
    color: "#c8102e",
    social: {
      bio: "On release, each episode was supported with organic social assets that ran on Facebook, Instagram, Twitter and Tik Tok. These assets captured the cold open from each episode and combined it with the series' unique illustration assets. Shorter paid social was also launched with unique animations, reaching over 1 million viewers and hitting a 40% higher engagement rate than standard social campaigns by the brand.",
      vids: [
        {
          vid: "https://robbieis.me/images/projects/homemade/trailer.mp4",
          poster: "https://robbieis.me/images/projects/homemade/trailerc.jpg",
        },
        {
          vid: "https://robbieis.me/images/projects/homemade/HMEP7S.mp4",
          poster: "https://robbieis.me/images/projects/homemade/prison.jpg",
        },
        {
          vid: "https://robbieis.me/images/projects/homemade/HMEP2S.mp4",
          poster: "https://robbieis.me/images/projects/homemade/truman.jpg",
        },

        {
          vid: "https://robbieis.me/images/projects/homemade/HMEP9S.mp4",
          poster: "https://robbieis.me/images/projects/homemade/ghost.jpg",
        },
        // {
        //   vid: "https://robbieis.me/images/projects/homemade/HMEP10s.mp4",
        //   poster: "https://robbieis.me/images/projects/homemade/farmer.jpg",
        // },
      ],
    },
    coretitle: "Select Episodes",
    coreexp:
      "The concept of home is unique to each and every one of us. Home. Made. connects with the diversity of those meanings through a 10-part documentary series hosted by emmy-award winning host Stephanie Foo.",
    block: (
      <Fragment>
        <iframe
          style={{ borderRadius: "12px", marginBottom: 25 }}
          src="https://open.spotify.com/embed/episode/20atwSB8hvY7ZFsSmt47CC?utm_source=generator&theme=0"
          width="100%"
          height="232"
          frameBorder="0"
          allowfullscreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        ></iframe>
        <iframe
          style={{ borderRadius: "12px", marginBottom: 25 }}
          src="https://open.spotify.com/embed/episode/4lRys4iwjAn4bP9N7PyIFT?utm_source=generator"
          width="100%"
          height="232"
          frameBorder="0"
          allowfullscreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        ></iframe>
        <iframe
          style={{ borderRadius: "12px", marginBottom: 25 }}
          src="https://open.spotify.com/embed/episode/7GyRnomanoI4kSS8PbQI6d?utm_source=generator&theme=0"
          width="100%"
          height="232"
          frameBorder="0"
          allowfullscreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        ></iframe>
        <iframe
          style={{ borderRadius: "12px", marginBottom: 25 }}
          src="https://open.spotify.com/embed/episode/1nGARcxnsPLK0mvhP4V4zk?utm_source=generator&theme=1"
          width="100%"
          height="232"
          frameBorder="0"
          allowfullscreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        ></iframe>
        <iframe
          style={{ borderRadius: "12px", marginBottom: 25 }}
          src="https://open.spotify.com/embed/episode/21D8SCMk1A6pRJSJg7r2H4?utm_source=generator&theme=0"
          width="100%"
          height="232"
          frameBorder="0"
          allowfullscreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        ></iframe>
        <iframe
          style={{ borderRadius: "12px", marginBottom: 25 }}
          src="https://open.spotify.com/embed/episode/2kS9bb10CwUesnzIydoTI4?utm_source=generator"
          width="100%"
          height="232"
          frameBorder="0"
          allowfullscreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        ></iframe>
      </Fragment>
    ),
    extensions:
      "Home. Made. relied on a full 360 podcast campaign, featuring extensions across numerous digital platforms, including brand email campaigns, host reads with partnering podcasts, paid and organic social, and a fully built out editorial experience.",
    // img2: "https://robbieis.me/images/projects/homemade/eps.png",
    // imgabove: "https://robbieis.me/images/projects/homemade/title.jpg",
    img1: "https://robbieis.me/images/projects/homemade/title.jpg",
    img2: "https://robbieis.me/images/projects/homemade/dig1.png",
    img3: "https://robbieis.me/images/projects/homemade/dig2.png",
    img4: "https://robbieis.me/images/projects/homemade/dig3.png",
    img5: "https://robbieis.me/images/projects/homemade/dig5.png",
    img6: "https://robbieis.me/images/projects/homemade/dig4.png",
    img7: "https://robbieis.me/images/projects/homemade/guide.jpg",

    // <iframe
    //       allow="autoplay *; encrypted-media *; fullscreen *"
    //       frameborder="0"
    //       height="175"
    //       style={{
    //         width: "100%",
    //         overflow: "hidden",
    //         background: "transparent",
    //       }}
    //       sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
    //       src="https://embed.podcasts.apple.com/us/podcast/the-artist-who-drew-himself-out-of-prison/id1557484598?i=1000526226005"
    //     ></iframe>{" "}
    //     <iframe
    //       allow="autoplay *; encrypted-media *; fullscreen *"
    //       frameborder="0"
    //       height="175"
    //       style={{
    //         width: "100%",
    //         overflow: "hidden",
    //         background: "transparent",
    //       }}
    //       sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
    //       src="https://embed.podcasts.apple.com/us/podcast/the-lady-is-a-saddle-tramp/id1557484598?i=1000556139501"
    //     ></iframe>
    //     <iframe
    //       allow="autoplay *; encrypted-media *; fullscreen *"
    //       frameborder="0"
    //       height="175"
    //       style={{
    //         width: "100%",
    //         overflow: "hidden",
    //         background: "transparent",
    //       }}
    //       sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
    //       src="https://embed.podcasts.apple.com/us/podcast/the-accidental-mayor-of-mobile-city/id1557484598?i=1000548777961"
    //     ></iframe>
    //     <iframe
    //       allow="autoplay *; encrypted-media *; fullscreen *"
    //       frameborder="0"
    //       height="175"
    //       style={{
    //         width: "100%",
    //         overflow: "hidden",
    //         background: "transparent",
    //       }}
    //       sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
    //       src="https://embed.podcasts.apple.com/us/podcast/the-man-and-the-mountain/id1557484598?i=1000516737139"
    //     ></iframe>
    //     <iframe
    //       allow="autoplay *; encrypted-media *; fullscreen *"
    //       frameborder="0"
    //       height="175"
    //       style={{
    //         width: "100%",
    //         overflow: "hidden",
    //         background: "transparent",
    //       }}
    //       sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
    //       src="https://embed.podcasts.apple.com/us/podcast/my-roommate-the-ghost/id1557484598?i=1000529298186"
    //     ></iframe>
  },
  {
    key: 2,
    order: 2,
    title: "Cyber Monday 2021",
    subtitle: "Just think what you could do with your savings.",
    role: "Creative Strategy & ACD",
    brand: "Rocket Mortgage",
    type: "Campaign",
    url: "http://robbieis.me/images/covers/CyberMonday2021.jpg",
    link: "/work/cyber-monday-2021",
    prType: "all creative TVC award select",
    deliverables: "TV Commercial, Email, Social, Digital",
    date: "2021",
    metrics: [
      { name: "Lead Production", result: "↑27%" },
      { name: "Run Time", result: "4 Days" },
    ],
    awards: [
      {
        body: "The Muse Awards",
        name: "Gold - Financial Services, 2022",
      },
      {
        body: "The Telly Awards",
        name: "Silver - Regional TV, 2022",
      },
    ],
    description:
      "Every year, direct response campaigns run with a focus on numbers. X% off here, X dollars in savings there, a sprinkle of some other percentage or number somewhere else. It becomes repetitive and meaningless.",
    descriptioncont: (
      <>
        <p>
          But it doesn't need to be. We understood that getting a great deal
          with Rocket Mortgage isn’t really about how much you’re saving, but
          about what you can do with that money.
        </p>{" "}
        <p>
          With five vignettes designed to target high value audiences, the idea
          behind this Cyber Monday campaign was simple: tell clients how much
          they can save; show them what they can do with those savings in a way
          that consistently evokes the thought “I want that!”
        </p>
      </>
    ),
    externallink: null,
    color: "#c8102e",
    blurb: null,
    fullwidth:
      "https://robbieis.me/images/projects/cybermonday21/fullwidth.jpg",
    fullwidth2:
      "https://robbieis.me/images/projects/cybermonday21/fullwidth2.jpg",
    social: {
      bio: "DR social ran on Facebook, Instagram and Twitter, and reached over 800,000 people. Each asset includes two vignettes, combined to target specific audiences, such as pool and the bathroom to target audiences interested in luxury, or the gamer and bathroom to target millennial workers.",

      vids: [
        {
          vid: "https://robbieis.me/images/projects/cybermonday21/Cm21sa.mp4",
          poster: "https://robbieis.me/images/projects/cybermonday21/cm1sc.jpg",
        },
        {
          vid: "https://robbieis.me/images/projects/cybermonday21/cm2s.mp4",
          poster: "https://robbieis.me/images/projects/cybermonday21/cm2sc.jpg",
        },
        {
          vid: "https://robbieis.me/images/projects/cybermonday21/cm21sbb.mp4",
          poster: "https://robbieis.me/images/projects/cybermonday21/cm3sc.jpg",
        },
        {
          vid: "https://robbieis.me/images/projects/cybermonday21/cm4s.mp4",
          poster: "",
        },
      ],
    },
    coretitle: "TVC",
    coreexp:
      "Install the backyard pool you've always wanted, create your dream sanctuary, or simply upgrade your kitchen and bring the family together. Just think what you could do with your Cyber Monday savings.",

    block: (
      <Fragment>
        <iframe
          src="https://player.vimeo.com/video/787039749?h=03bb24d8e0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          className={classes.ytvid}
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          title="Cyber Monday 2021.mp4"
        ></iframe>
      </Fragment>
    ),
    extensions:
      "Cyber Monday ran on all brand and internal channels. This included a site takeover, multiple email campaigns, servicing dashboard integrations, and an internal hype video.",
    img1: "https://robbieis.me/images/projects/cybermonday21/dig1.png",
    img2: "https://robbieis.me/images/projects/cybermonday21/dig3.png",
    img3: "https://robbieis.me/images/projects/cybermonday21/dig2.png",
    img4: "https://robbieis.me/images/projects/cybermonday21/emails.jpg",
  },

  {
    key: Math.random().toString(),
    order: 11,
    title: "Rocket Auto",
    brand: "Rocket Central",
    subtitle: "Making that hidden auto gem visible.",
    role: "Creative Strategy & ACD",
    type: "Branding",
    url: "http://robbieis.me/images/covers/rocketauto.jpg",
    link: "/work/rocket-auto",
    prType: "all creative digital ",
    deliverables: "Branding & Digital",
    date: "2021",
    metrics: [
      { name: "Y1 Visitor Growth", result: "1M+" },
      { name: "Y1 Lead Growth", result: "30k" },
    ],

    description:
      " Rocket Auto launched in a market suffering the effects of price inflation and supply chain shortages. 50% fewer consumers were planning to buy a new vehicle, and those that were needed to plan for more than a 30% increase in price over what they could have gotten 12 months prior.",
    descriptioncont: (
      <>
        <p></p>
        <p>
          As a search aggregator, the Rocket Auto launch needed to tap into the
          idea of the hidden gem – the idea that consumers could win the auto
          lottery without having to sift through hundreds, or thousands, of
          listings. The platform's search capabilities meant that narrowing down
          to unique recommendations was as easy as configuring a handful of
          preferences and hitting search.
        </p>
        <p>
          Creative did this by highlighting the search and selection features of
          the product and positioning it as simple, convenient, and trustworthy.
          For car buyers, this meant finding that gem was now only a click away.
        </p>
      </>
    ),
    externallink: null,
    color: "#c70e2d",
    fullwidth: "http://robbieis.me/images/projects/rocketauto/fullwidth2.jpg",
    fullwidth2: "http://robbieis.me/images/projects/rocketauto/fullwidth.jpg",
    code: true,
    extensions:
      "Extensions for the launch campaign included email campaigns, OLA, and direct mail targeting existing Rocket Companies clients.",
    social: {
      bio: "The Rocket Auto social campaign aimed to focus on the search and find capabilities of the Auto platform, while also dialing in on specific vehicle types that resonated with the target audience. SUVs and electric vehicles made up the initial round, with trucks like the F-150 being built in to round 2.",
      pics: [
        {
          pic: "http://robbieis.me/images/projects/rocketauto/static4.png",
        },
        {
          pic: "http://robbieis.me/images/projects/rocketauto/static1.jpg",
        },
        {
          pic: "http://robbieis.me/images/projects/rocketauto/static2.jpg",
        },
        {
          pic: "http://robbieis.me/images/projects/rocketauto/static5.jpg",
        },
        {
          pic: "http://robbieis.me/images/projects/rocketauto/static6.jpg",
        },
      ],
      vids: [
        {
          vid: "https://robbieis.me/images/projects/rocketauto/vid1.mp4",
        },
        {
          vid: "https://robbieis.me/images/projects/rocketauto/vid3.mp4",
        },
        {
          vid: "https://robbieis.me/images/projects/rocketauto/vid4.mp4",
        },
      ],
    },
    coretitle: "Teaser",
    coreexp:
      "When looking for a used car, what are you actually looking for? A 2019 Ford Escape with 5 doors, heated seats, and a price tag between $20,000 and $25,000? Or a hidden gem that seems too good to be true and makes you feel like you just won the lottery? While most buyers settle for the former, a significant portion want it to be the latter.",
    block: (
      <>
        <center style={{ marginBottom: 40 }}>
          <video className={classes.ytvid} controls>
            <source
              src="https://robbieis.me/images/projects/rocketauto/15vid.mp4"
              type="video/mp4"
            />
          </video>
        </center>
      </>
    ),
    img1: "http://robbieis.me/images/projects/rocketauto/dig1.png",
    img2: "http://robbieis.me/images/projects/rocketauto/dig2.png",
    img3: "http://robbieis.me/images/projects/rocketauto/dig3.png",
    img4: "http://robbieis.me/images/projects/rocketauto/dig4.png",
  },
  {
    key: 1,
    order: 1,
    brand: "Rocket Mortgage & Rocket Homes",
    title: "The Rocket Advantage",
    type: "TVC",
    url: "https://robbieis.me/images/covers/dr22.jpg",
    link: "/work/bought-a-home",
    deliverables: "TV COMMERCIAL, OLA, SOCIAL, DIGITAL",
    prType: "all creative award TVC select",
    role: "Creative Strategy",
    subtitle: "Buying a home is easier when someone has your back.",
    color: "#c8102e",
    date: "2022",
    description:
      "Only 14 percent of consumers believe that their financial institution cares about their financial health. Within the mortgage space, those beliefs are only compounded by a perception that mortgage companies manipulate and trap consumers into plans and payments they don’t fully understand.",
    descriptioncont: (
      <>
        <p></p>
        <p>
          Yet despite these concerns, 89 percent of young people are still
          looking to own a home. To do that, they need someone they can trust to
          help guide them through the process. For Rocket, this has meant
          finding solutions to their problems. Rates rising? Lock today's rate
          and keep it while you shop for a home. Rates too high? We’ll help you
          get into a lower rate. Rates likely to drop in the future? We’ll offer
          a credit so you can refinance into that lower rate as soon as it's
          available. These and more are genuine advantages – Rocket Advantages -
          showing that Rocket has their backs.
        </p>
      </>
    ),
    fullwidth:
      "https://robbieis.me/images/projects/rocketadvantage/fullwidth2.jpg",
    fullwidth2:
      "https://robbieis.me/images/projects/rocketadvantage/fullwidth.jpg",

    // metrics: [
    //   { name: "Lead Production", result: "50k+" },
    //   { name: "Run Time", result: "4 months" },
    // ],
    awards: [
      {
        body: "The Muse Awards",
        name: "Gold - TV Ad, 2022",
      },
    ],

    social: {
      bio: "The Rocket Advantage social campaign focused primarily on direct response ads run across Facebook, Twitter and Tik Tok. These ads captured elements from the TVC and combined them with historically high-performing DR approaches. ",
      vids: [
        {
          vid: "https://robbieis.me/images/projects/rocketadvantage/social1.mp4",
          poster:
            "https://robbieis.me/images/projects/rocketadvantage/cover1.jpg",
        },
        {
          vid: "https://robbieis.me/images/projects/rocketadvantage/social2.mp4",
          poster:
            "https://robbieis.me/images/projects/rocketadvantage/cover2.jpg",
        },
      ],
      pics: [
        {
          pic: "https://robbieis.me/images/projects/rocketadvantage/social3.jpg",
        },
        {
          pic: "https://robbieis.me/images/projects/rocketadvantage/social4.jpg",
        },
      ],
    },
    coretitle: "TVC",
    coreexp:
      "Buying a home is difficult. And now, on top of all that, you’ve got to consider a tough market with rising rates? Rocket Mortgage gives you back the advantage with benefits designed to help you compete regardless of where you stand. That’s the Rocket Advantage.",
    vid1: "https://player.vimeo.com/video/787037354?h=3ed106973a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    vid2: "https://player.vimeo.com/video/948084668?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    extensions:
      "The Rocket Advantage ran as a full campaign, with a pre-TVC email campaign, OLA, direct mail, and a custom landing page.",
    img4: "https://robbieis.me/images/projects/rocketadvantage/dig3.png",
    img1: "https://robbieis.me/images/projects/rocketadvantage/dig1.png",
    img2: "https://robbieis.me/images/projects/rocketadvantage/dig2.png",
    img3: "https://robbieis.me/images/projects/rocketadvantage/dig4.png",
  },

  // {
  //   key: Math.random().toString(),
  //   order: 7,
  //   title: "Captain Kicks",
  //   subtitle: "Soccer training for kids.",
  //   role: "Copywriter",
  //   type: "Website",
  //   url: "http://robbieis.me/images/covers/ck.jpg",
  //   link: "/work/captain-k",
  //   prType: "digital",
  //   date: "2022",
  //   deliverables: "Website",
  //   color: "#b571d6",
  //   description:
  //     "Captain Kicks offers non-competitive soccer for kids in California, through team games and activities.",
  //   descriptioncont: (
  //     <>
  //       <p>
  //         Discoursed makes use of a Node.js backend with all functionality being
  //         handled behind the scenes and the results delivered straight to a
  //         React frontend. The frontend makes use of a redux store in order to
  //         make results available across the app. This includes looking at
  //         detailed concordance lines in the explore section.
  //       </p>
  //       <p>
  //         While standard concordancers incorporate all tokens, what makes
  //         Discoursed unique is its emphasis on topic and sentiment
  //         identification. This is done through the use of purpose-based
  //         dictionaries that allow for removal of boilerplate, leaving only
  //         significant tokens and topics behind.
  //       </p>
  //     </>
  //   ),
  //   block3: (
  //     <div className={classes.container}>
  //       <div className={classes.twothirdcol}>
  //         <img src="http://robbieis.me/images/projects/captainkicks/pods.png" />
  //       </div>
  //       <div className={classes.threecolitem}>
  //         <img src="http://robbieis.me/images/projects/captainkicks/parties.png" />
  //       </div>
  //     </div>
  //   ),

  //   fullwidth: "http://robbieis.me/images/projects/captainkicks/fullwidth.jpg",
  //   img1: "http://robbieis.me/images/projects/captainkicks/home.png",
  // },
  {
    key: Math.random().toString(),
    order: 7,
    title: "Discoursed",
    subtitle: "A web-based discourse analysis toolset.",
    role: "Creator",
    type: "App",
    url: "http://robbieis.me/images/covers/discoursed.jpg",
    link: "/work/discoursed",
    prType: "",
    date: "2022",
    description:
      "Discoursed is a simple, web-based concordancer designed to make understanding the most prevalent and important topics within a certain discourse easy. ",
    descriptioncont: (
      <>
        <p>
          Discoursed makes use of a Node.js backend with all functionality being
          handled behind the scenes and the results delivered straight to a
          React frontend. The frontend makes use of a redux store in order to
          make results available across the app. This includes looking at
          detailed concordance lines in the explore section.
        </p>
        <p>
          While standard concordancers incorporate all tokens, what makes
          Discoursed unique is its emphasis on topic and sentiment
          identification. This is done through the use of purpose-based
          dictionaries that allow for removal of boilerplate, leaving only
          significant tokens and topics behind.
        </p>
      </>
    ),
    block: (
      <div className={classes.container}>
        <div className={classes.threecolitem}>
          <img src="http://robbieis.me/images/projects/discoursed/home1.jpg" />
        </div>
        <div className={classes.twothirdcol}>
          <img src="http://robbieis.me/images/projects/discoursed/dash1.jpg" />
        </div>
      </div>
    ),
    deliverables: "Web App",
    color: "#14425a",
    fullwidth: "http://robbieis.me/images/projects/discoursed/fullwidth.jpg",
    code: true,
    codeb: (
      <div className={classes.blurb}>
        <code>
          <b>Tech:</b> JavaScript, Express, React, NodeJS, Redux
          <div className={classes.boxOuter}>
            {/* <a
              className={classes.box}
              href="https://github.com/LuoTheBuilder/Caloriecheckerv2"
              target="_blank"
            >
              <img className={classes.icon} src={ghicon} />
              See the Repo
            </a>
            <br /> */}
            <a
              className={classes.box}
              href="https://discoursedio.herokuapp.com/"
              target="_blank"
            >
              <img className={classes.icon} src={webicon} />
              See the Site
            </a>
          </div>
        </code>
      </div>
    ),
  },

  {
    key: 3,
    order: 3,
    title: "Big Change",
    subtitle: "How little steps can add up to make big lifestyle changes.",
    brand: "Rocket Mortgage",
    role: "Creative Strategy & ACD",
    type: " Series",
    url: "http://robbieis.me/images/covers/BigChange.jpg",
    link: "/work/big-change",
    prType: "creative  award digital",
    deliverables: "YouTube Series",
    date: "2021",
    metrics: [
      { name: "Total Views", result: "12m+" },
      { name: "Average View Time", result: "60%+" },
      { name: "Influencers", result: "13+" },
    ],
    quotes: [
      "This is pretty cool. I didn't think I would find Rocket Mortgage on YouTube of all places...",
      "These are fantastic tips, especially from trusted people. ",

      "Here because of Babish. Love it.",
      "Came here from girlfriend reviews! this looks awesome.",
    ],
    awards: [
      {
        body: "The Muse Awards",
        name: "Silver - Influencer  and Brand Partnership, 2022",
      },
      {
        body: "The Telly Awards",
        name: "Bronze - Online Series, 2022",
      },
      {
        body: "The Shorty Awards",
        name: "Nominee - Influencer and Brand Partnership, 2022",
      },
    ],

    description:
      "Homeownership is about more than just interest rates and monthly payments, it’s about putting yourself in a position of financial security and achieving lifestyle goals. ",
    descriptioncont: (
      <>
        <p>Let’s face it, managing your finances is boring. </p>{" "}
        <p>
          You know what isn’t boring? Learning how to become a master chef,
          being able to shred like the rock gods of old, thrifting your way into
          a full Armani or Versace outfit, or seeing how you could realistically
          afford to buy and live on a private island.{" "}
        </p>
        <p>
          But these things don’t have to be separate. By focusing on the
          interests of Rocket Mortgage high value audiences, Big Change bridges
          that gap. It does this by connecting with some of YouTube’s biggest
          influencers to explore the tiny steps they took that led them to where
          they are today. It’s about making the little things exciting and
          showing how, together, they add up to make Big Change.{" "}
        </p>
      </>
    ),
    externallink: {
      type: "watch",
      link: "https://www.youtube.com/c/RocketLearn",
    },
    color: "#c70e2d",
    fullwidth:
      "http://robbieis.me/images/projects/bigchange/BigChangeCover.jpg",
    social: {
      bio: "The Big Change social campaign was crucial to the success of the series. In addition to standard organic placements across Facebook and Twitter and paid placements across YouTube, promotions hosted on videos by influencers featured on the channel were key. This included both pre-roll prior to episodes, and advertisements embedded within episodes. ",
      pics: [
        {
          pic: "http://robbieis.me/images/projects/bigchange/bcs1.jpg",
        },
        {
          pic: "http://robbieis.me/images/projects/bigchange/bcs2.jpg",
        },
      ],
      vids: [
        {
          vid: "https://robbieis.me/images/projects/bigchange/babishpre.mp4",
          poster: "https://robbieis.me/images/projects/bigchange/babishpre.jpg",
        },
        {
          vid: "https://robbieis.me/images/projects/bigchange/martypre.mp4",
          poster: "https://robbieis.me/images/projects/bigchange/martypre.jpg",
        },
      ],
    },
    vid1: "https://www.youtube.com/embed/f12SDuHmCIQ",
    vid2: "https://www.youtube.com/embed/u3606FW5LCk?si=53hphK71wJ6nkngU",
    vid3: "https://www.youtube.com/embed/2Vw6BbrXqHs?si=U_sLyyfLfuUUSOOS",

    coretitle: "Select Episodes",

    img1: "http://robbieis.me/images/projects/bigchange/eps.jpg",
  },
  {
    key: 1,
    order: 1,
    brand: "Rocket Central",
    title: "Home Lore",
    type: "Series",
    url: "https://robbieis.me/images/covers/hl3.png",
    link: "/work/home-lore",
    deliverables: "Series",
    prType: "all award creative digital select",
    role: "Creative Strategy & ACD",
    subtitle:
      "Stories from small towns, major cities, and everywhere in between.",
    color: "#c8102e",
    date: "2022",
    description:
      "As Rocket moved into developing more long-form video content, the need to amplify organic effectiveness only increased.",
    descriptioncont: (
      <>
        <p>
          Yet virality on YouTube has increasingly become less about chance and
          more about relevance and depth of experience. Home Lore brings that
          depth of experience to life with an episodic format that captures the
          popularity of YouTube “documentaries” and ties it back to the concept
          of home.
        </p>
        <p>
          Episodes span from New York to Hawaii, Texas to Wisconson, and feature
          in-depth explorations of the history, people, and architecture present
          in the USA.
        </p>
      </>
    ),
    fullwidth: "https://robbieis.me/images/projects/homelore/fullwidth.jpg",
    fullwidth2: "https://robbieis.me/images/projects/homelore/fullwidth2.jpg",
    metrics: [
      { name: "Total Views", result: "10m+" },
      { name: "Average View Time", result: "70%+" },
    ],
    awards: [
      {
        body: "The Telly Awards",
        name: "Bronze - Branded Content, 2023",
      },
    ],
    coretitle: "Select Episodes",
    coreexp:
      "From frontier castles to solar schoolhouses, Home Lore explores historically relevant and odd homes, uncovering their stories and capturing the American tales you probably haven’t heard. From small towns to major cities, and everywhere in between, this is Home Lore.",
    vid1: "https://www.youtube.com/embed/F-XRlJS9PRI?si=xwn8YuCQPgdJ0Ys0",
    vid2: "https://www.youtube.com/embed/pEFlyyocT6I",
    vid3: "https://www.youtube.com/embed/vAm5ybu9x8U?si=Zh0KH06bPWUgCyvC",
    // vid3desc: "sdfsdfsdf3",

    // block3: (
    //   <div>
    //     <iframe
    //       width="49%"
    //       margin="10px"
    //       height="800"
    //       src="https://www.youtube.com/embed/LWp2qpmnBG4"
    //       title="YouTube video player"
    //       frameborder="0"
    //       allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    //       allowfullscreen
    //     ></iframe>
    //     <iframe
    //       width="49%"
    //       margin="10px"
    //       height="800"
    //       src="https://www.youtube.com/embed/B0pELC5IKrI"
    //       title="YouTube video player"
    //       frameborder="0"
    //       allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    //       allowfullscreen
    //     ></iframe>
    //   </div>
    // ),
  },

  {
    key: 6,
    order: 6,
    title: "The Red Desk",
    subtitle: "Your guide to everything home buying, selling, and financing.",
    brand: "Rocket Mortgage",
    role: "Creative Strategy & ACD",
    type: "Series",
    url: "http://robbieis.me/images/covers/TheRedDesk.jpg",
    link: "/work/red-desk",
    prType: "all creative YouTube award digital ",
    deliverables: "YouTube Series",
    date: "2021",
    metrics: [
      { name: "Total Views", result: "10m+" },
      { name: "Average View Time", result: "60%+" },
      { name: "Minutes Watched", result: "16m+" },
    ],
    quotes: [
      "This is great!  Educating the consumer on the process helps them so much!  Wish they had videos like this in my day!  Thanks for sharing!",
      "Will forever appreciate this channel, you have helped my family alot. Your videos, advise, lessons are inspirational and helpful to us. My wife and I have been able to be minimal, conscious in spending, Saving and investing wisely.",
    ],
    awards: [
      { body: "The Muse Awards", name: "Gold - YouTube Social Media, 2022" },
      {
        body: "The Telly Awards",
        name: "Silver - Online Series, 2022",
      },
      { body: "The Shorty Awards", name: "Nominee - Branded Series, 2022" },
    ],
    description:
      "Clients aren't looking for a company to tell them how to do their mortgage, they’re looking for a person they can trust. Enter The Red Desk, the place to demystify everything about the home buying and homeownership process.",
    descriptioncont: (
      <>
        <p>
          Mortgages are complicated, and the process of buying a new home can be
          daunting. If it’s not trying to figure out what escrow actually means,
          then it’s trying to pick apart your interest rate and closing costs to
          find out if it really makes sense for you to buy.
        </p>
        <p>
          The Red Desk makes things easier by targeting real home buyer
          questions and sharing meaningful answers in an easy to digest way.
          Based on detailed research into the SEO terms and topics being asked,
          The Red Desk demystifies everything about the home buying, selling,
          and financing process you could ever need.
        </p>
      </>
    ),
    externallink: {
      type: "watch",
      link: "https://www.youtube.com/c/RocketLearn",
    },

    color: "#c70e2d",
    extensions: true,
    fullwidth:
      "http://robbieis.me/images/projects/thereddesk/TheRedDeskCover.jpg",
    social: {
      bio: true,
      vids: [
        {
          vid: "https://robbieis.me/images/projects/thereddesk/TRDteaser1.mp4",
          // poster: "http://robbieis.me/images/projects/rocketcan/rs1.jpg",
        },
        {
          vid: "https://robbieis.me/images/projects/thereddesk/TRDteaser2.mp4",
        },
        // {
        //   vid: "https://robbieis.me/images/projects/thereddesk/TRDteaser3.mp4",
        // },
      ],
    },
    coretitle: "Select Episodes",
    vid1: "https://www.youtube.com/embed/t_Xwg21ieV0?si=ciDTQ_d5ouFBRg0c",
    vid2: "https://www.youtube.com/embed/_AfF_-MYIlI",
    vid3: "https://www.youtube.com/embed/Ki_Ayh8JBwM?si=CJbjRewlNkQghtEi",
    img1: "http://robbieis.me/images/projects/thereddesk/extensions.jpg",
  },
  {
    key: 5,
    order: 5,
    brand: "Rocket Mortgage",
    title: "Rocket Can",
    subtitle: "Lending a competitive edge to home buyers nationwide.",
    role: "Creative Strategy & ACD",
    type: "TVC",
    url: "http://robbieis.me/images/covers/RocketCan.jpg",
    link: "/work/rocket-can",
    prType: "TVC ",
    deliverables: "TV Commercial",
    date: "2020",

    metrics: [
      { name: "Time in Market", result: "1 year" },
      { name: "Cost Per Integrated Lead", result: "Down 54%" },
    ],
    description:
      "From competing with other buyers for that perfect home, to the simple pleasures of a new backyard renovation, clients are looking for a lender that can help make their dream a reality. Rocket Can.",
    descriptioncont: (
      <>
        <p>
          In 2020, the housing market was tough. Increased demand meant
          increased competition for homes, with many buyers needing to pay above
          asking price or forgo a home inspection to even stand a chance of
          winning in a bidding war. The phrase “cash is king” seemed to appear
          everywhere, as standard offers were almost always dismissed in favor
          of the security offered by cash.{" "}
        </p>

        <p>
          Yet it didn’t need to be this way. A Rocket Mortgage verified approval
          is more than a typical approval letter. It verifies a buyer’s
          financial situation so a seller knows they can buy the home.{" "}
        </p>

        <p>
          {" "}
          A fusion of illustration and product brought a brand new approach to
          DRTV, one that helped combine product messaging with stories that
          resonated with home buyers. Not only did this approach provide
          consistency with other touchpoints, thereby providing greater support
          for the larger Rocket Mortgage marketing ecosystem, but it also
          allowed for a simplified message that drove higher conversion rates.
        </p>
      </>
    ),
    externallink: null,
    color: "#c8102e",
    fullwidth: null,
    social: {
      bio: true,

      vids: [
        {
          vid: "https://robbieis.me/images/projects/rocketcan/1.mp4",
          poster: "http://robbieis.me/images/projects/rocketcan/rs1.jpg",
        },
        {
          vid: "https://robbieis.me/images/projects/rocketcan/2.mp4",
          poster: "http://robbieis.me/images/projects/rocketcan/rs2.jpg",
        },
        {
          vid: "https://robbieis.me/images/projects/rocketcan/3.mp4",
          poster: "http://robbieis.me/images/projects/rocketcan/rs3.jpg",
        },
      ],
    },
    extensions: true,
    coretitle: "TVC",
    block: (
      <iframe
        src="https://player.vimeo.com/video/798062185?h=52dc18d69e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
        width="100%"
        height="720"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen
        title="rcp30.mp4"
      ></iframe>
    ),
    img1: "http://robbieis.me/images/projects/rocketcan/extensions.jpg",
  },

  // {
  //   key: Math.random().toString(),
  //   order: 7,
  //   title: "MindJacker",
  //   role: "Programmer",
  //   type: "App",
  //   url: "http://robbieis.me/images/covers/mindjacker1.png",
  //   link: "/work/mindjacker",
  //   prType: "code select",
  //   date: "2022",
  //   description:
  //     "Mind Jacker is a simple, web-based concordancer designed to make understanding the most prevalent and important topics within a certain discourse easy. ",
  //   descriptioncont: (
  //     <>
  //       <p>
  //         MindJacker makes use of a Node.js backend with all functionality being
  //         handled behind the scenes and the results delivered straight to a
  //         React frontend. The frontend makes use of a redux store in order to
  //         make results available across with app. Future iterations will make
  //         use of this to build out the explore functionality – which is still in
  //         development.{" "}
  //       </p>
  //       <p>
  //         While standard concordancers incorporate all tokens, what makes
  //         MindJacker unique is its emphasis on topic identification. This is
  //         done through the use of purpose-based dictionaries that allow for
  //         removal of boilerplate, leaving only significant tokens and topics
  //         behind.
  //       </p>
  //     </>
  //   ),
  //   deliverables: "Web App",
  //   color: "rgb(148, 193, 232)",
  //   fullwidth: "http://robbieis.me/images/projects/mindjacker/fullwidth.jpg",
  //   code: true,
  //   codeb: (
  //     <div className={classes.blurb}>
  //       <code>
  //         <b>Tech:</b> JavaScript, Express, React, NodeJS, Redux
  //         <div className={classes.boxOuter}>
  //           {/* <a
  //             className={classes.box}
  //             href="https://github.com/LuoTheBuilder/Caloriecheckerv2"
  //             target="_blank"
  //           >
  //             <img className={classes.icon} src={ghicon} />
  //             See the Repo
  //           </a>
  //           <br /> */}
  //           <a
  //             className={classes.box}
  //             href="https://mindjacker.herokuapp.com/"
  //             target="_blank"
  //           >
  //             <img className={classes.icon} src={webicon} />
  //             See the Site
  //           </a>
  //         </div>
  //       </code>
  //     </div>
  //   ),
  // },
  // {
  //   key: Math.random().toString(),
  //   order: 7,
  //   title: "Calorie Checker V2",
  //   role: "Programmer",
  //   type: "App",
  //   url: "http://robbieis.me/images/covers/calcheck1.png",
  //   link: "/work/Calorie-Checker-2",
  //   prType: "digital",
  //   date: "2022",
  //   description:
  //     "The second iteration of the Calorie Checker web app designed to track calorie intake. This version incorporated user authentication (Google and local), utilized a centralized redux store, and was designed using the Material UI library. It also featured a more complex database design with private routes.",
  //   deliverables: "Fullstack MERN",
  //   color: "#45a746",
  //   fullwidth: "http://robbieis.me/images/projects/caloriecheck/fullwidth2.jpg",
  //   code: true,
  //   codeb: (
  //     <div className={classes.blurb}>
  //       <code>
  //         <b>Tech:</b> JavaScript, MongoDB, Express, React, NodeJS, Redux,
  //         Material UI
  //         <div className={classes.boxOuter}>
  //           <a
  //             className={classes.box}
  //             href="https://github.com/LuoTheBuilder/Caloriecheckerv2"
  //             target="_blank"
  //           >
  //             <img className={classes.icon} src={ghicon} />
  //             See the Repo
  //           </a>
  //           <br />
  //           <a
  //             className={classes.box}
  //             href="https://calcheck.herokuapp.com/"
  //             target="_blank"
  //           >
  //             <img className={classes.icon} src={webicon} />
  //             See the Site
  //           </a>
  //           <br />
  //           <a className={classes.box} href="/work/Calorie-Checker-App">
  //             See V1
  //           </a>
  //         </div>
  //       </code>
  //     </div>
  //   ),
  // },
  {
    key: 7,
    order: 8,
    brand: "Rocket Central",
    title: "More Space for Activites",
    subtitle:
      "Because when you're looking to buy a home, that's exactly what you want.",
    role: "Creative Strategy & ACD",
    type: "Super Bowl Pitch",
    url: "http://robbieis.me/images/covers/Superbowl2021.jpg",
    link: "/work/superbowl",
    prType: " TVC select",
    deliverables: "Super Bowl Commercial Pitch",
    date: "2020",
    Metric1: null,
    Metric1perf: null,
    Metric2: null,
    Metric2perf: null,
    description:
      "The More Space For Activities pitch centered around one simple concept: that homeowners and searchers were looking for space.  ",
    descriptioncont: (
      <>
        <p>
          We all remember 2020: the year the pandemic started. And as the US
          moved to working from home, this pitch tapped into the cultural
          consciousness of the time by leveraging a line from a movie that had
          just, twelve years prior, captured an entire generation with its
          irreverent humor: the Step Brothers were back.
        </p>
        <p>
          This pitch placed 3rd out of more than 150 pitches presented to Rocket
          Mortgage.{" "}
        </p>
      </>
    ),
    externallink: null,
    color: "#c8102e",
    fullwidth: null,
    block: (
      <iframe
        src="https://player.vimeo.com/video/798062445?h=075ec39398&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
        width="100%"
        height="1080"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen
        title="QKN_Step_Brothers.mp4"
      ></iframe>
    ),
    img1: null,
  },

  {
    key: Math.random().toString(),
    order: 12,
    title: "Rocket Puede",
    brand: "Rocket Mortgage",
    subtitle:
      "Lending a competitive edge to Spanish-speaking home buyers nationwide.",
    role: "Creative Strategy",
    type: "TVC",
    url: "http://robbieis.me/images/covers/RocketPuede.jpg",
    link: "/work/puede",
    prType: "TVC",
    deliverables: "TV Commercial",
    date: "2021",

    description:
      "Rocket Puede is the Spanish language equivalent of Rocket Can, sharing the benefits and possibilities that come with refinancing or buying a new home with Rocket.",
    descriptioncont: (
      <>
        <p>
          Small but significant changes were made to the existing Rocket Can
          creative in order to speak to cultural moments that Hispanics can
          identify with, aligning specific product benefits with this specific
          audience’s requirements.
        </p>
      </>
    ),
    externallink: null,
    color: "#c8102e",
    fullwidth: null,
    block: (
      <center>
        <video
          width="100%"
          controls
          poster="https://robbieis.me/images/projects/puede/pueder1.png"
        >
          <source
            src="https://robbieis.me/images/projects/puede/rpr30.mp4"
            type="video/mp4"
          />
        </video>
        <video
          width="100%"
          controls
          poster="https://robbieis.me/images/projects/puede/puedep1.png"
        >
          <source
            src="https://robbieis.me/images/projects/puede/rpp30.mp4"
            type="video/mp4"
          />
        </video>
      </center>
    ),
  },

  // {
  //   key: Math.random().toString(),
  //   order: 13,
  //   title: "Tech Whiz",
  //   brand: "Rocket Mortgage",
  //   subtitle:
  //     "It may be Cyber Monday but you don't have to be a tech whiz to take advantage.",
  //   role: "Creative Strategist",
  //   type: "Campaign",
  //   url: "http://robbieis.me/images/covers/CyberMonday2020.jpg",
  //   link: "/work/tech-whiz",
  //   prType: "digital ",
  //   deliverables: "Digital Campaign",
  //   date: "2020",
  //   metrics: [
  //     { name: "Lead Production", result: "↑140%" },
  //     { name: "Run time", result: "2 Days" },
  //   ],
  //   description:
  //     "Clients think that Cyber Monday is only for tech, but it’s not. You don’t have to be a tech whiz to take advantage of these savings from Rocket Mortgage. Just look at The Fitness Guru, The Rockstar, and The Master Chef – each one quarantine masters looking to realize their dream home this Cyber Monday. ",
  //   descriptioncont: (
  //     <>
  //       <p>
  //         2021 saw a huge opportunity for mortgage companies across the US.
  //         Historically low rates meant that it was cheaper than ever before for
  //         consumers to secure a mortgage but increasing home prices and a highly
  //         competitive market meant it was harder than ever before to secure a
  //         new dream home. The solution was focusing on the home improvement
  //         possibilities that came with a cash-out refinance – something that
  //         each of the quarantine masters represented.
  //       </p>
  //       <p>
  //         Despite market dips as a result of the pandemic, this was the most
  //         successful and resonant sale campaign Rocket Mortgage had run up until
  //         this point.
  //       </p>
  //     </>
  //   ),
  //   externallink: null,
  //   color: "#c8102e",
  //   fullwidth: "http://robbieis.me/images/projects/cybermonday20/fullwidth.jpg",
  //   social: {
  //     bio: "Cyber Monday social ran across Facebook, Twitter and Instagram, across both paid and organic. Brand influencers like Rickie Fowler also tweeted about the sale, extending awareness and helping boost traction.",
  //     vids: [
  //       {
  //         vid: "https://robbieis.me/images/projects/cybermonday20/fgsocial.mp4",
  //         poster: "",
  //       },
  //       {
  //         vid: "https://robbieis.me/images/projects/cybermonday20/rssocial.mp4",
  //         poster: "",
  //       },
  //       {
  //         poster:
  //           "https://robbieis.me/images/projects/cybermonday20/cm20inf.png",
  //       },
  //     ],
  //   },
  //   block: (
  //     <div className={classes.container}>
  //       <div className={classes.threecolitem}>
  //         <img src="https://robbieis.me/images/projects/cybermonday20/RS.jpg" />
  //       </div>
  //       <div className={classes.threecolitem}>
  //         <img src="https://robbieis.me/images/projects/cybermonday20/FG.jpg" />
  //       </div>
  //       <div className={classes.threecolitem}>
  //         <img src="https://robbieis.me/images/projects/cybermonday20/MC.jpg" />
  //       </div>
  //     </div>
  //   ),
  //   block2: (
  //     <div className={classes.container}>
  //       <div className={classes.threecolitem}>
  //         <img src="https://robbieis.me/images/projects/cybermonday20/cme3.jpg" />
  //       </div>
  //       <div className={classes.threecolitem}>
  //         <img src="https://robbieis.me/images/projects/cybermonday20/cme1.jpg" />
  //       </div>
  //       <div className={classes.threecolitem}>
  //         <img src="https://robbieis.me/images/projects/cybermonday20/cme2.jpg" />
  //       </div>
  //     </div>
  //   ),
  // },
  // {
  //   key: 1,
  //   order: 1,
  //   brand: "Rocket Central",
  //   title: "Financial Life",
  //   role: "Creative Strategist & ACD",
  //   subtitle: "Financial servicing done right.",
  //   type: "Print & Digital",
  //   url: "https://robbieis.me/images/covers/rms.jpg",
  //   link: "/work/odl",
  //   deliverables: "Print & Digital",
  //   prType: "all print creative digital ",
  //   color: "#c8102e",
  //   date: "2022",
  //   description:
  //     "From surprise and delight giveaways to partnerships with organizations like Google, and newsletters that provide real value, being a Rocket Client means having access to more than just your mortgage profile.",
  //   descriptioncont: (
  //     <>
  //       <p>
  //         The truth is that once a client has closed on their mortgage, most
  //         clients will only engage with their servicer when they need to pay a
  //         bill or check their equity before taking advantage of a cash-out
  //         refinance. We wanted to change that.
  //       </p>
  //       <p>
  //         And it wasn’t just Rocket Mortgage we wanted clients to engage with.
  //         It was also other brands within the Rocket portfolio. Rocket Auto,
  //         Rocket Homes, Rocket Loans, and Rocket Solar could all add value to
  //         the client experience and improve their financial lives. Below is a
  //         selection of assets that were created to aid in this effort.
  //       </p>
  //     </>
  //   ),
  //   fullwidth: "https://robbieis.me/images/projects/rms/fullwidth.jpg",
  //   metrics: [
  //     { name: "Lead Generation", result: "Up to 8X increase" },
  //     { name: "Welcome Video View Duration", result: "Up 40%" },
  //     // { name: "Audience Retention", result: "85%" },
  //   ],
  //   block: (
  //     <Fragment>
  //       <center>
  //         <video
  //           width="100%"
  //           controls
  //           poster="https://robbieis.me/images/projects/rocketadvantage/cafecouple.jpg"
  //         >
  //           <source
  //             src="https://robbieis.me/images/projects/rocketadvantage/rocketadvantage30.mp4"
  //             type="video/mp4"
  //           />
  //         </video>
  //       </center>
  //     </Fragment>
  //   ),
  //   img1: "https://robbieis.me/images/projects/rms/serv1.png",
  //   img2: "https://robbieis.me/images/projects/rms/serv2.jpg",
  //   img3: "https://robbieis.me/images/projects/rms/serv3.png",
  //   img4: "https://robbieis.me/images/projects/rms/serv4.png",
  //   img5: "https://robbieis.me/images/projects/rms/serv5.png",
  // },
  {
    key: Math.random().toString(),
    order: 11,
    brand: "A Liquid Web Brand",
    title: "Nexcess",
    role: "Content Strategist",
    subtitle: "Bridging the web hosting divide between developer and business.",
    type: "Website",
    url: "http://robbieis.me/images/covers/Nexcess.jpg",
    link: "/work/nexcess",
    prType: "all  creative digital  ",
    deliverables: "Brand",
    date: "2019",
    metrics: [
      { name: "Monthly Revenue Growth", result: "30%" },
      { name: "Organic Growth", result: "80%" },
    ],

    description:
      "A site design rooted in technical but accessible copy and a sitemap that guides clients to the solutions they are looking for, reaffirming that Nexcess helps realize the promise of the cloud for businesses and agencies worldwide.",
    descriptioncont: (
      <>
        <p>
          Web hosting companies have a problem. They don’t know who they’re
          meant to talk to. In some web development agencies, it’s the developer
          that makes hosting decisions. They’re looking for proof of detailed
          knowledge and an ability to optimize. In others, it’s business leaders
          that make the decision. They’re looking for support and turnkey
          solutions. Both are radically different, so how do you market to both?
        </p>
        <p>
          The Nexcess redesign was developed after carefully curating over five
          years of creative data. It aimed to target both developers and
          business stakeholders by offering simple descriptions for complex
          problems, and underlining it with a look and feel that appealed to
          developer sensibilities.
        </p>
        <p>
          By doing this, we not only helped business stakeholders to understand
          the true difference in service, but also told developers that their
          decision mattered too.
        </p>
      </>
    ),
    externallink: null,
    color: "#028fc7",
    fullwidth: "http://robbieis.me/images/projects/nexcess/fullwidth.png",
    code: true,
    block: (
      <video width="100%" controls className={classes.container}>
        <source
          src="http://robbieis.me/images/projects/nexcess/bbint.mp4"
          type="video/mp4"
        />
      </video>
    ),
    block2: (
      <div className={classes.container}>
        <div className={classes.threecolitem}>
          <img src="http://robbieis.me/images/projects/nexcess/Cloud.jpg" />
        </div>
        <div className={classes.threecolitem}>
          <img src="http://robbieis.me/images/projects/nexcess/Mage.jpg" />
        </div>
        <div className={classes.threecolitem}>
          <img src="http://robbieis.me/images/projects/nexcess/WP.jpg" />
        </div>
      </div>
    ),
    img1: "http://robbieis.me/images/projects/nexcess/icons1.png",
    img2: "http://robbieis.me/images/projects/nexcess/nexboo.jpg",
    img3: "http://robbieis.me/images/projects/nexcess/nexboo2.jpg",
    img4: "http://robbieis.me/images/projects/nexcess/nexban.jpg",
    img5: "http://robbieis.me/images/projects/nexcess/nexswag2.png",
  },

  {
    key: Math.random().toString(),
    order: 15,
    brand: "Rocket Mortgage",
    subtitle: '"All we need is some stock and a message that resonates."',
    title: "We're With You",
    role: "Creative Strategist",
    type: "TVC",
    url: "http://robbieis.me/images/covers/WereWithYou.jpg",
    link: "/work/were-with-you",
    prType: "TVC",
    deliverables: "TV Commercial",
    date: "2020",
    metrics: [
      { name: "Brand Meaningfulness", result: "↑6%" },
      { name: "Run time", result: "30 days" },
    ],
    description:
      "We're With You was designed to address the COVID-19 situation, sending a message of reassurance not only to new clients, but also to those who already had a mortgage with us. A message that said 'We’re with you.'",
    descriptioncont: (
      <>
        <p>
          By the end of April 2020, everyone had launched a COVID commercial.
          For many companies, it was a band-aid approach, telling the general
          public that we’re in this together. But for consumers, these spots
          instead became synonymous with fake advertising, false sentiment, and
          broken promises.
        </p>
        <p>
          The Rocket Mortgage spot launched in March, just slightly ahead of
          everyone else. To hit this deadline, we used curated stock and text
          treatments. The secret was in the script's resonance with public
          discourse surrounding COVID. Yes, we said we’re in this together, but
          we also offered a way to alleviate the emerging financial burden the
          public felt like they were about to face. Because of this, instead of
          generating criticism and backlash, the spot saw brand meaningfulness
          increase to the highest level in 3 years among intenders.
        </p>
      </>
    ),
    externallink: null,
    color: "#c8102e",
    fullwidth: null,
    block: (
      <center>
        <video
          width="100%"
          controls
          poster="http://robbieis.me/images/projects/werewithyou/WWYCA.jpg"
        >
          <source
            src="http://robbieis.me/images/projects/werewithyou/wereherefor.mp4"
            type="video/mp4"
          />
        </video>
      </center>
    ),
  },
  {
    key: Math.random().toString(),
    order: 16,
    brand: "Thermo.io",
    title: "Thermo.io",
    subtitle: "Web hosting designed to meet the needs of developers.",
    role: "Content Strategy",
    type: "Branding",
    url: "http://robbieis.me/images/covers/Thermo.jpg",
    link: "/work/thermo",
    prType: "all creative digital print",
    deliverables: "Brand & Technical Writing",
    date: "2018",
    metrics: [
      { name: "Organic Growth", result: "540%" },
      { name: "Social Growth", result: "300%" },
    ],
    description:
      "Thermo.io was created to meet the needs of a startup audience looking to move away from AWS and adopt a hosting platform they could help mold.",
    descriptioncont: (
      <>
        <p>
          Limited budget meant limited resources. We optimized our strategy
          around long-term SEO goals and immediate social engagement, prefering
          to avoid the alienating effects of lead forms and build relationships
          of trust with developers instead. A series of icons and illustrations
          were created to support this effort across all marketing initiatives.
          These were centered around Hopper, the mysterious astronaut figure at
          the center of Thermo.io.
        </p>
        <p>
          We knew our audience, we just needed to create content that resonated
          with them. I led a team of in-house writers and developers to create
          content that began the first building blocks of trust. Our product was
          a sandbox. It didn't come with anything preinstalled - not even Linux.
          As such, we needed a knowledge base - one that led developers to
          success. And we created it.
        </p>
        <p>
          Digital and event strategies were augmented with strategic placement
          of print advertisements in industry publications. Combined with
          impressive coupon codes and discounts, these helped to increase brand
          awareness and build advocates. A social strategy that focused on small
          wins to build community advocates and spread the word, as opposed to
          large-scale awareness with no actual connections, was implemented
          alongside all events. This increased social engagement more than 300%.{" "}
        </p>
      </>
    ),
    externallink: null,
    color: "#f8560f",
    lexicon: "http://robbieis.me/images/projects/thermo/thermlex.png",
    blurb:
      "Bringing Thermo.io to market meant aligning ourselves with innovation and change. With a limited budget, we adopted an almost entirely organic-based content strategy at first, using social media as a vehicle for engagement and well-researched content aimed at developers as a springboard for click-through. I not only created marketing content, but also in-depth developer guides and tutorials.",
    fullwidth: "http://robbieis.me/images/projects/thermo/fullwidth.jpg",
    block: (
      <div className={classes.container}>
        <div className={classes.threecolitem}>
          <img src="http://robbieis.me/images/projects/thermo/thrmblg1.png" />
        </div>
        <div className={classes.threecolitem}>
          <img src="http://robbieis.me/images/projects/thermo/thrmblg2.png" />
        </div>
        <div className={classes.threecolitem}>
          <img src="http://robbieis.me/images/projects/thermo/thrmblg3.png" />
        </div>
      </div>
    ),
    img1: "http://robbieis.me/images/projects/thermo/thermdsk.jpg",
    img2: "http://robbieis.me/images/projects/thermo/thermicn.png",
    img3: "http://robbieis.me/images/projects/thermo/thermmag.jpg",
    img4: "http://robbieis.me/images/projects/thermo/thermswag.jpg",
  },

  {
    key: Math.random().toString(),
    order: 17,
    brand: "Nexcess",
    title: "State of Hosting",
    subtitle:
      "The publication for knowing what is happening in the hosting industry.",
    role: "Copywriter",
    type: "WhitePaper",
    url: "http://robbieis.me/images/covers/StateOfHosting.png",
    link: "/work/state-of-hosting",
    prType: "",
    deliverables: "White Paper & Technical Writing",
    date: "2018",

    description:
      "The State of Hosting was envisioned as a publication designed to increase thought leadership in the hosting space and position Nexcess as one of the leading authorities on hosting infrastructure and environments for businesses and agencies.",
    descriptioncont: (
      <>
        <p>
          This white paper was launched in both digital and print formats and
          distributed through email, social, display and during events. Social
          posts, revealing insights from the state of hosting were used to
          generate further buzz.
        </p>
        <p>
          {" "}
          The success of the State of Hosting led to many other white papers and
          eBooks being published both digitally and in print. These would
          continue to be given out at events, kept behind gated lead forms, or
          simply provided to community advocates as a means of further building
          thought leadership.
        </p>
      </>
    ),
    externallink: null,
    color: "#028fc7",
    lexicon: null,
    blurb: null,
    fullwidth:
      "http://robbieis.me/images/projects/stateofhosting/fullwidth.jpg",
    block: (
      <div className={classes.container}>
        <div className={classes.threecolitem}>
          <img src="http://robbieis.me/images/projects/stateofhosting/sohblg1.png" />
        </div>
        <div className={classes.threecolitem}>
          <img src="http://robbieis.me/images/projects/stateofhosting/sohblg2.png" />
        </div>
        <div className={classes.threecolitem}>
          <img src="http://robbieis.me/images/projects/stateofhosting/sohblg3.png" />
        </div>
      </div>
    ),
    img1: "http://robbieis.me/images/projects/stateofhosting/nexsoh5.jpg",
    img2: "http://robbieis.me/images/projects/stateofhosting/soh3.jpg",
  },

  {
    key: Math.random().toString(),
    order: 18,
    title: "Preventure",
    subtitle:
      "Helping teens and their families find ways to prevent future drug use.",
    role: "Copywriting",
    type: "Website",
    url: "http://robbieis.me/images/covers/Preventure.png",
    link: "/work/preventure",
    prType: "award digital ",
    deliverables: "Website",
    date: "2018",
    metrics: [{ name: "Lead Growth", result: "1900%" }],
    quotes: [
      "We’ve seen a 1900% increase in inquiries. From the way the program is presented to the easy-to-follow tabs and pages, each click allows the user to easily find what they need. We had no idea of what we were looking for in a website but they were brilliant in their delivery and design.",
    ],

    description:
      "Preventure offers a preventative approach to teen drug use and abuse. ",
    descriptioncont: (
      <>
        <p>
          This site was a part of the program's first push into the US market.
          As a result, the site design and content was vital in not only making
          the program easy to understand and join, but also in providing
          participants an intuitive user experience throughout. Content on the
          site was designed to be as clear as possible, providing clear next
          steps for those who wanted to take part. This assumed that the website
          would act as part of an omnichannel strategy that would likely start
          in the classroom.
        </p>
      </>
    ),
    externallink: null,
    color: "#f4d06f",
    lexicon: null,

    awards: [{ body: "AWWWards", name: "Site of the Day" }],
    blurb:
      "This site won an Awwwards Site of the Day. Founded in Canda, this site was a part of the program's first push into the US market. As a result, the site design and content was vital in not only making the program easy to understand and join, but also in providing participants an intuitive user experience throughout. Content on the site was designed to be as clear as possible, providing clear next steps for those who wanted to take part. This assumed that the website would act as part of an omnichannel strategy that would likely start in the classroom.",
    fullwidth: "http://robbieis.me/images/projects/preventure/fullwidth.jpg",
    block: (
      <div className={classes.container}>
        <div className={classes.threecolitem}>
          <img src="http://robbieis.me/images/projects/preventure/prvexp1.png" />
        </div>
        <div className={classes.threecolitem}>
          <img src="http://robbieis.me/images/projects/preventure/prvexp2.png" />
        </div>
        <div className={classes.threecolitem}>
          <img src="http://robbieis.me/images/projects/preventure/prvexp3.png" />
        </div>
      </div>
    ),
    block2: (
      <div className={classes.container}>
        <div className={classes.threecolitem}>
          <img src="http://robbieis.me/images/projects/preventure/home.jpg" />
        </div>
        <div className={classes.threecolitem}>
          <img src="http://robbieis.me/images/projects/preventure/facilitator.jpg" />
        </div>
        <div className={classes.threecolitem}>
          <img src="http://robbieis.me/images/projects/preventure/program.jpg" />
        </div>
      </div>
    ),
  },
  {
    key: 833,
    order: 19,
    title: "Speechling",
    subtitle: "An editorial and SEO strategy grounded in learning Chinese.",
    role: "Content Strategy & Writer",
    type: "",
    url: "http://robbieis.me/images/covers/speechling.jpg",
    link: "/work/speechling",
    prType: " digital",
    deliverables: "Editorial",
    date: "2017",
    type: "Editorial",
    metrics: [
      { name: "Search Impressions", result: "10M+" },
      { name: "#1 Search Rankings", result: "13" },
    ],
    description:
      "Speechling is a digital language learning platform that offers language learning services for numerous languages, including Mandarin Chinese.",
    descriptioncont: (
      <>
        <p>
          In developing an editorial strategy for Speechling, a comprehensive
          SEO map was developed, highlighting opportunities around Chinese
          language acquisition search terms. This was combined with an audience
          benefit analysis, leading to a content roadmap based on long tail
          keywords. As of June 2018, content ranked #1 for 13 high-traffic
          keywords and has had more than 10M impressions.
        </p>
      </>
    ),
    externallink: {
      type: "read",
      link: "https://speechling.com/blog/author/robert/",
    },
    fullwidth: "https://robbieis.me/images/projects/speechling/fullwidth.jpg",
    color: "#e6cd98",
    block: (
      <div className={classes.container}>
        <div className={classes.threecolitem}>
          <img src="http://robbieis.me/images/projects/speechling/splgblg1.png" />
        </div>
        <div className={classes.threecolitem}>
          <img src="http://robbieis.me/images/projects/speechling/splgblg2.png" />
        </div>
        <div className={classes.threecolitem}>
          <img src="http://robbieis.me/images/projects/speechling/splgblg3.png" />
        </div>
      </div>
    ),
  },
  {
    key: 4,
    order: 4,
    title: "Emon's Indian Takeaway",
    subtitle: "An Indian takeaway rebrand that focuses on spice.",
    role: "Branding",
    type: "Branding",
    url: "http://robbieis.me/images/covers/Emons.jpg",
    link: "/work/Emons",
    prType: " design print",
    deliverables: "Brand",
    date: "2021",

    description:
      "Curry is about the roar of spice and the rumble of flavor. A nuanced mix of Cardamom, Turmeric and Red Chili bring out the best in Indian takeaway, which has become as much a part of being British as it has a measure of stamina. ",
    descriptioncont: (
      <>
        <p>
          Emon's is a small Indian Curry House based in my hometown of
          Southampton, UK. Having grown up enjoying curry, with Emon’s being my
          go-to restaurant for takeout, I jumped at the chance to work on a
          small rebrand. This aimed to pivot Emon’s generic branding that made
          use of pinks and purples into something that better reflected the
          British cultural emphasis on spice in Indian dishes.
        </p>
        <p>
          Three "flavors" were created, using a traffic light coloring system to
          highlight increasing levels of spice. Language was also used to
          complement these variations, with mild spice simply "rumbling" and
          stronger spice "roaring". Check out some of the initial creative
          below.
        </p>
      </>
    ),
    externallink: {
      type: "see",
      link: "https://emonsindiantakeaway.co.uk",
    },
    color: "#34654f",
    fullwidth: "http://robbieis.me/images/projects/emons/fullwidth.jpg",

    // img1: "http://robbieis.me/images/projects/emons/orderapp.jpg",
    img2: "http://robbieis.me/images/projects/emons/logoexp.jpg",
    img3: "http://robbieis.me/images/projects/emons/logo-red.png",
    img4: "http://robbieis.me/images/projects/emons/menu-scaled.jpg",
    img5: "http://robbieis.me/images/projects/emons/packaging.jpg",
    img6: "http://robbieis.me/images/projects/emons/social.jpg",
  },
  {
    key: 8,
    order: 19,
    title: "Published Photography",
    role: "Photography",
    type: "",
    url: "http://robbieis.me/images/covers/Photography.jpg",
    link: "/work/photography",
    prType: "all creative award",
    deliverables: "Photography",
    date: "2016",
    awards: [
      { body: "Through Foreign Eyes 2016", name: "1st" },
      { body: "Through Foreign Eyes 2015", name: "2nd" },
      { body: "National Geographic", name: "Tibet, Published Online" },
      { body: "National Geographic", name: "Emei Shan, Published Online" },
    ],
    description: "A collection of photography taken when I lived in China.",
    descriptioncont: (
      <>
        <p>
          The first two images won 1st and 2nd in the Through Foreign Eyes
          photography competition. The first one resulted in a pop-up gallery
          featuring my photography in Shanghai. The second two images were
          published online on NationalGeographic.com.
        </p>
      </>
    ),
    externallink: null,
    blurb: "",
    color: "#590b9e",
    blurg: null,
    img1: "http://robbieis.me/images/projects/photography/people.jpg",
    img2: "http://robbieis.me/images/projects/photography/geese.jpg",
    img3: "http://robbieis.me/images/projects/photography/tibet.jpg",
    img4: "http://robbieis.me/images/projects/photography/emei.jpg",
  },

  {
    key: Math.random().toString(),
    order: 20,
    brand: "Nexcess",
    title: "Nexcess Events",
    subtitle: "Designed to help tie threads between business and ops.",
    role: "Strategist",
    type: "Activation",
    url: "http://robbieis.me/images/covers/NexcessEvents.jpg",
    link: "/work/Nexcess-events",
    prType: "print",
    deliverables: "Live Activations",
    date: "2018",
    description:
      "Nexcess started out small but quickly rose to prominence after Magento was created using it as a platform for development. Since then, Nexcess has grown to become a global brand, known by agency and business owners alike as the place where they can find managed web hosting that helps them realize the promise of their online presence.",
    fullwidth: "http://robbieis.me/images/projects/nexcess/nexswag.jpg",
    externallink: null,
    color: "#028fc7",
    blurg: null,
    block: (
      <video width="100%" controls>
        <source
          src="http://robbieis.me/images/projects/nexcess/bbint.mp4"
          type="video/mp4"
        />
      </video>
    ),

    img1: "http://robbieis.me/images/projects/nexcess/nexboo.jpg",
    img2: "http://robbieis.me/images/projects/nexcess/nexboo2.jpg",
    img3: "http://robbieis.me/images/projects/nexcess/nexban.jpg",
    img4: "http://robbieis.me/images/projects/nexcess/nexswag2.png",
  },
  {
    key: 8,
    order: 21,
    title: "LaowaiCareer",
    subtitle: "A careers site that needed to prove its authenticity.",
    role: "Creative",
    type: "Website",
    url: "http://robbieis.me/images/covers/LaowaiCareer.png",
    link: "/work/laowai",
    prType: "digital",
    fullwidth: "http://robbieis.me/images/projects/laowai/fullwidth.jpg",
    deliverables: "Website",
    date: "2015",
    description:
      "LaowaiCareer was a new job search website, looking to capitalize on the growing jobs market for foreigners in China. As such, building out the site meant making sure it was easily accessible from search engines, that it took advantage of a simple UX, and that it provided proof of its authenticity.",
    externallink: null,
    blurb: null,
    color: "#207d4e",
    blurg: null,
    block: (
      <div className={classes.container}>
        <div className={classes.threecolitem}>
          <img src="http://robbieis.me/images/projects/laowai/mob1.png" />
        </div>
        <div className={classes.threecolitem}>
          <img src="http://robbieis.me/images/projects/laowai/mob2.png" />
        </div>
        <div className={classes.threecolitem}>
          <img src="http://robbieis.me/images/projects/laowai/mob3.png" />
        </div>
      </div>
    ),
    img1: "http://robbieis.me/images/projects/laowai/web.png",
  },
  {
    key: "asass",
    order: 1,
    brand: "Association pour la Solidarité et l’Assistance Socio-Sanitaire",
    title: "ASASS",
    role: "Content Strategy",
    type: "Website",
    url: "http://robbieis.me/images/covers/ASASS.jpg",
    link: "/work/asass",
    prType: "award digital",
    deliverables: "Website, Social",
    date: "2016",
    color: "#75b3d5",
    metrics: [{ name: "Donation Growth", result: "300%+" }],
    awards: [{ body: "United Nations", name: "Certificate of Appreciation" }],
    description:
      "Association pour la Solidarité et l’Assistance Socio-Sanitaire (ASASS) is a non-profit that supports education, health, hygiene, and rural development initiatives in Burundi, Rwanda, Tanzania, and Uganda. ",
    descriptioncont: (
      <>
        <p>
          As a relatively unknown non-profit, ASASS needed two things: awareness
          and a simple online donation process. With almost no budget, the first
          was managed through a guerrilla style marketing strategy that
          leveraged organic social posts to generate word of mouth.
        </p>
        <p>
          The second was harder to solve due to a problem with currency
          transfers from western countries to Burundi. I spearheaded partnership
          development with multiple western agencies and organizations in order
          to build a reliable intermediary system for processing donations and
          ensuring the money made it to the programs it was meant to support.
        </p>
        <p>
          Due to this work with ASASS, I was awarded a certificate of
          appreciation from the UN and ASASS was awarded a financial grant to
          expand access to services such as the Kanyosha community health center
          and the farmers credit union coop.
        </p>
      </>
    ),
    fullwidth: "http://robbieis.me/images/projects/asass/fullwidth.jpg",
    img1: "http://robbieis.me/images/projects/asass/asassdsk.png",
    block2: (
      <div className={classes.container}>
        <div className={classes.threecolitem}>
          <img src="http://robbieis.me/images/projects/asass/asassexp1.png" />
        </div>
        <div className={classes.threecolitem}>
          <img src="http://robbieis.me/images/projects/asass/asassexp2.png" />
        </div>
        <div className={classes.threecolitem}>
          <img src="http://robbieis.me/images/projects/asass/asassexp3.png" />
        </div>
      </div>
    ),

    social: {
      bio: "The ASASS social strategy focused on developing an organic Facebook presence and leveraging that to push donations through the website. Newsworthy content was shared, as well as profiles on those helped by ASASS.",
      pics: [
        {
          pic: "http://robbieis.me/images/projects/asass/soc1.jpg",
        },
        {
          pic: "http://robbieis.me/images/projects/asass/soc2.jpg",
        },
      ],
    },
  },
  {
    key: 9,
    order: 22,
    brand: "Efuton Tea",
    title: "The Tea Fields of Hangzhou",
    role: "Copywriter",
    type: "Video",
    url: "http://robbieis.me/images/covers/efuton.jpg",
    link: "/work/efuton",
    prType: "digital",
    deliverables: "Digital Video",
    date: "2015",
    fullwidth: "",
    description:
      "If you’ve made it this far, congrats! You just stumbled on something of a gem. An advertisement I translated for and starred in, I just couldn’t help but keep it here as a memento to that one time I stood on a mountain top and screamed into the abyss for a cup of tea. ",
    descriptioncont:
      "For Chinese consumers, tea is more than simply a drink. It’s an opportunity to return to their roots and infuse themselves with the taste of adventure. With Efuton, they can explore a world of tea, starting in the tea fields of Hangzhou and ending with a refreshing cup of chá (茶).",
    externallink: null,
    color: "#8ca54b",
    block: (
      <center>
        <video width="100%" controls>
          <source
            src="http://robbieis.me/images/projects/Efuton/yibeicha.mp4"
            type="video/mp4"
          />
        </video>
      </center>
    ),
  },

  // {
  //   key: Math.random().toString(),
  //   order: 9,
  //   title: "Calorie Checker V1",
  //   role: "Programmer",
  //   type: "App",
  //   url: "http://robbieis.me/images/covers/calcheck.jpg",
  //   link: "/work/Calorie-Checker-App",
  //   prType: "",
  //   date: "2021",
  //   description:
  //     "The first iteration of a simple web app designed to track calorie intake throughout the week. This version was limited to 5 days of tracking, and had no user authentication, so made use of a simple express/ MongoDB backend. All CSS was original with no UI library.",
  //   deliverables: "Fullstack MERN",
  //   color: "#e97325",
  //   fullwidth: "http://robbieis.me/images/projects/caloriecheck/fullwidth.jpg",
  //   code: true,
  //   codeb: (
  //     <div className={classes.blurb}>
  //       <code>
  //         <b>Tech:</b> JavaScript, MongoDB, Express, React, NodeJS
  //         <div className={classes.boxOuter}>
  //           <a
  //             className={classes.box}
  //             href="https://github.com/LuoTheBuilder/calorieCheck"
  //             target="_blank"
  //           >
  //             <img className={classes.icon} src={ghicon} />
  //             See the Repo
  //           </a>
  //           <br />

  //           <a className={classes.box} href="/work/Calorie-Checker-2">
  //             See V2
  //           </a>
  //         </div>
  //       </code>
  //     </div>
  //   ),
  // },
  // {
  //   key: Math.random().toString(),
  //   order: 23,
  //   title: "Note Taker",
  //   role: "Programmer",
  //   type: "App",
  //   url: "http://robbieis.me/images/covers/nv2.jpg",
  //   link: "/work/Note-Taker",
  //   prType: "code",
  //   date: "2022",
  //   description:
  //     "Was this one of the first tutorials I took? Yes, it was. Did I learn a lot of about JavaScript and code structure? Yes, I did.",
  //   deliverables: "EJS & Express",
  //   color: "#e97325",
  //   fullwidth: "",
  //   code: true,
  //   codeb: (
  //     <div className={classes.blurb}>
  //       <code>
  //         <b>Tech:</b> JavaScript, EJS, HTML, CSS, Express
  //         <div className={classes.boxOuter}>
  //           <a
  //             className={classes.box}
  //             href="https://github.com/LuoTheBuilder/todolistv2"
  //             target="_blank"
  //           >
  //             <img className={classes.icon} src={ghicon} />
  //             See the Repo
  //           </a>
  //         </div>
  //       </code>
  //     </div>
  //   ),
  //   img1: "http://robbieis.me/images/projects/caloriecheck/noteappv2.jpg",
  // },
  // {
  //   key: "asd",
  //   order: 14,
  //   title: "Wedding Site",
  //   role: "Dev & Design",
  //   type: "Website",
  //   url: "http://robbieis.me/images/covers/Weddingweb.jpg",
  //   link: "/work/wedding-site",
  //   prType: "code ",
  //   deliverables: "React Frontend Express Mailserver",
  //   date: "2022",
  //   fullwidth: "",
  //   description:
  //     "A simple one page React wedding site combined with an Express mailserver to forward automated reservation mails generated in a contact form to the organziers. Site features include modal form treatment, custom image gallery, a real-time countdown timer, and a custom nodemailer server deployed on Heroku.",
  //   externallink: null,

  //   color: "#207d4e",
  //   blurg: null,
  //   code: true,
  //   codeb: (
  //     <div className={classes.blurb}>
  //       <code>
  //         <b>Tech:</b> JavaScript, React, Express, Nodemailer
  //         <div className={classes.boxOuter}>
  //           <a
  //             className={classes.box}
  //             href="https://github.com/LuoTheBuilder/WeddingRSVPExpressMailServer"
  //             target="_blank"
  //           >
  //             <img className={classes.icon} src={ghicon} />
  //             See the Repo
  //           </a>
  //           <br />
  //           <a
  //             className={classes.box}
  //             href="https://rosieandseanswedding.com/"
  //             target="_blank"
  //           >
  //             <img className={classes.icon} src={webicon} />
  //             See the Site
  //           </a>
  //         </div>
  //       </code>
  //     </div>
  //   ),
  //   block2: (
  //     <div className={classes.singlepage}>
  //       <img
  //         className={classes.singlepageimg}
  //         src="http://robbieis.me/images/projects/weddingsite/wedmainsm.jpg"
  //       />
  //     </div>
  //   ),
  // },
];

export default projects;
