import React from "react";
import classes from "./Box.module.css";

const Box = (props) => {
  return (
    <div className={classes.boxouter}>
      {props.title && <div className={classes.boxtitle}>{props.title}</div>}
      <div
        className={classes.box}
        style={
          (props.shadow
            ? { boxShadow: "0px 10px 30px 0px rgb(0 0 0 / 10%" }
            : { backgroundColor: `${props.backgroundColor}` },
          { minHeight: props.height })
        }
      >
        <div className={classes.boxcontent}>{props.children}</div>
      </div>
    </div>
  );
};

export default Box;
