import React from "react";
import TagCloud from "TagCloud";

const Cloud = (words, box, width) => {
  console.log(words);
  const myTags = words;
  var tagCloud = TagCloud(box, myTags, {
    // radius in px
    radius: width,

    // animation speed
    // slow, normal, fast
    maxSpeed: "slow",
    initSpeed: "slow",

    // 0 = top
    // 90 = left
    // 135 = right-bottom
    direction: 135,

    // interact with cursor move on mouse out
    keep: true,
  });
};

const Tagcloudy = ({ words, box, width }) => {
  return (
    <div className="centeredbox">
      {setTimeout(() => {
        Cloud(words, box, width);
      }, 1)}
    </div>
  );
};

export default Tagcloudy;
