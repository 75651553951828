import React, { createContext, useState } from "react";
import projects from "../Project.js";

const ProjectContext = createContext({
  projects: [],
  updateProjects: () => {},
});

export const ProjectContextProvider = (props) => {
  const [projectState, setProjectState] = useState(projects);

  const updateProjectsHandler = (prType) => {
    const filtered = projects.filter((project) =>
      project.prType.includes(prType)
    );
    setProjectState(filtered);
  };
  return (
    <ProjectContext.Provider
      value={{ projects: projectState, updateProjects: updateProjectsHandler }}
    >
      {props.children}
    </ProjectContext.Provider>
  );
};

export default ProjectContext;
