import React, { Fragment } from "react";

import classes from "../ProjectPage.module.css";
import phone from "../../Assets/phone.png";
import Video from "./Video";

const Social = ({ data }) => {
  return (
    <Fragment>
      {data.bio && (
        <div className={classes.exp}>
          <h7 className="lines">Social</h7>
          {/* <div className={classes.expline} /> */}
          <p>{data.bio}</p>
        </div>
      )}
      <div className={classes.cont}>
        {data.vids &&
          data.vids.map((video) => (
            <Video src={video.vid} poster={video.poster} />
          ))}
        {data.pics &&
          data.pics.map((pic) => (
            <div className={classes.vidholder}>
              <div className={classes.vidinner}>
                <center>
                  <img src={pic.pic} />
                </center>
              </div>
            </div>
          ))}
      </div>
    </Fragment>
  );
};
export default Social;
