import React, { Fragment } from "react";
import classes from "./About.module.css";
import me from "../Assets/me.png";
import Header from "../Layout/Header";
import Helmet from "react-helmet";

const About = () => {
  return (
    <Fragment>
      <Helmet>
        <title>About | Robbie Bailey</title>
      </Helmet>
      <Header />
      <div className={classes.titlebox}>
        <div className={classes.title}>
          <h1>
            <b>Hi. I'm Robbie, An Award-Winning Writer & Creative </b>
          </h1>
          <img
            className={classes.award}
            src="https://robbieis.me/images/awards/shorty.jpg"
          />

          <img
            className={classes.award}
            src="https://robbieis.me/images/awards/signal.jpg"
          />

          <img
            className={classes.award}
            src="https://robbieis.me/images/awards/telly.jpg"
          />
          <img
            className={classes.award}
            src="https://robbieis.me/images/awards/ihaf.jpg"
          />
          <img
            className={classes.award}
            src="https://robbieis.me/images/awards/digicma.jpg"
          />
        </div>
        <img
          className={classes.mepic}
          src="https://robbieis.me/images/me242.jpg"
        />
      </div>

      {/* <div className={classes.mepic}>
        <center>
          {" "}
          <img className={classes.me} src={me} />
        </center>
      </div> */}

      <div className={classes.mainholder2}>
        <p>
          I like to write. I also like to design… and code… and concept… and
          come up with ideas that genuinely challenge the status quo in a way
          that is beneficial for everyone. The topic doesn't matter. I’ve worked
          on everything from hotcakes to mortgages, single send emails looking
          for leads to in-depth podcast series that seek to explore the human
          condition.
        </p>
        <b>2014-2017</b>
        <p>
          It was in 2014 that, just by happenstance, I was offered my first
          freelance gig. It was writing longtail review articles for Amazon
          products. Glorious is not an adjective that can be used here. I often
          ended up pumping out in excess of 4,000 words a day, covering
          everything from high-fidelity sound systems to low-quality bug
          repellent. When I think about this time, the oft-misunderstood quote
          that it takes 10,000 hours to become a master of something always
          comes to mind.
        </p>
        <p>
          But it was during this time that I began to formulate a process for
          integrating my knowledge of linguistics and discourse into my writing,
          tapping into topic and sentiment opportunities that competitors were
          missing out on. They simply lacked the tools that allowed for this
          type of analysis and application.
        </p>
        <p>
          Following the success of this approach, I started to pick up bigger
          clients: international clients. I moved from writing longtail reviews
          to complex blog articles on topics like finance, translation, and
          marketing and reworking entire brand messaging approaches. I also
          started to look beyond simply writing and provide a larger collection
          of services that could have a bigger impact. This included everything
          from design to code (I put together this site in React). I even tried
          my hand at photography – having work published on
          NationalGeographic.com, used in national advertising campaigns, and
          winning two competitions and having my own pop-up gallery in Shanghai
          from one of those wins.
        </p>
        <b>2017- Now</b>
        <p>
          In 2017, I moved to the US and have since worked with numerous
          in-house agencies including Nexcess, Liquid Web and Rocket Mortgage
          (formerly Quicken Loans). I continue to work with Freelance clients,
          and especially enjoy working with brands that have struggled to stand
          out from the crowd and need to innovate to stay ahead.
        </p>
        <p>
          In 2020, I published my first book <i>The Language of Brands</i>. This
          book explains a lot of the theory and processes that go into my unique
          approach to writing and marketing. You can pick it up on Amazon for a
          couple of bucks, or just read the first couple of chapters for free.
        </p>
        <p>
          I’ve led direction on television commercials, developed multiple
          award-winning YouTube series, and launched a successful podcast - one
          that has been described as “[introducing] a whole new sound to branded
          podcasts”.
        </p>
        <p>
          {/* You'll currently find me at Amazon, where I'm helping to build an
          in-house creative team to support Amazon Business Payments and
          Lending. */}
        </p>
        <p>
          Interested in working together? Feel free to connect on LinkedIn and
          let's talk about what you've got going.{" "}
        </p>
      </div>
      <div className={classes.mainholder}>
        <div className={classes.box}>
          <h4>EXPERIENCE</h4>
          <ul>
            {/* <li>
              <b>Amazon</b> : Head of Creative, 2023-present{" "}
            </li> */}
            <li>
              <b>Amazon</b> : Head of Creative & Brand Marketing, 2023-present
            </li>
            <li>
              <b>Amazon</b> : Sr. Content Marketing Manager, 2022-2023
            </li>
            <li>
              <b>RB Consulting</b> : Creative, Strategist, Dev, 2014-Present
            </li>
            <li>
              <b>Rocket Mortgage</b> : Creative Strategist, 2020-2022{" "}
            </li>

            <li>
              <b>Nexcess</b> : Senior Content Strategist, 2018-2020
            </li>
            <li>
              <b>New Window Marketing</b> : Content & Copywriter, 2016-2017
            </li>
          </ul>
          <h4>EDUCATION</h4>
          <ul>
            {/* <li>
              <b>Cornell University</b> : Certificate in Design Thinking
            </li> */}
            <li>
              <b>Cornell University</b> : Certificate Design Thinking
            </li>
            <li>
              <b>Brandman University</b> : MBA Marketing
            </li>
            <li>
              {" "}
              <b>Lancaster University</b> : MA Language and Linguistics
            </li>
            <li>
              <b>Aberystwyth University</b> : BA Film & Television Studies /
              English Literature (Joint Hons.)
            </li>
            <li>
              <b>Sichuan Normal University</b> : Chinese Language and Literature
            </li>
          </ul>
          <h4>SELECT CLIENTS</h4>
          <ul>
            <li>
              Amazon // Rocket Mortgage // Rocket Auto // Nexcess // Liquid Web
              // British Gas // Zen Rooms // Luxury Escapes// Speechling // The
              United Nations
            </li>
          </ul>
        </div>
        <div className={classes.boxright}>
          <h4>AWARDS</h4>
          <ul>
            <li>
              <b>The Shorty Awards</b>:{" "}
              <a href="/work/home-made-podcast">Winner - Branded Podcast</a> |{" "}
              <a href="/work/home-made-podcast">
                Audience Honor - Branded Podcast
              </a>{" "}
              | <a href="/work/red-desk">Nominee - Branded Series</a> |{" "}
              <a href="/work/big-change">
                Nominee - Influencer and Brand Partnership
              </a>
            </li>
            <li>
              <b>The Signal Awards</b>:{" "}
              <a href="/work/home-made-podcast">Gold - Lifestyle</a> |{" "}
              <a href="/work/home-made-podcast">Bronze - Craft-Best Host</a>
            </li>
            <li>
              <b>The Muse Awards</b> :{" "}
              <a href="/work/home-made-podcast">Gold - Branded Podcast</a> |{" "}
              <a href="/work/cyber-monday-2021">
                Gold - Financial Services Video
              </a>{" "}
              | <a href="/work/red-desk">Gold - Social Media YouTube</a> |{" "}
              <a href="/work/bought-a-home">Gold - TV Ad</a> |{" "}
              <a href="">Silver - Branded Content - E-Book</a> |{" "}
              <a href="/work/big-change">
                Silver - Influencer and Brand Partnership
              </a>
            </li>

            <li>
              <b>IHAF</b> : <a href="/work/home-made-podcast">Gold</a>
            </li>
            <li>
              <b>The Telly Awards</b> :{" "}
              <a href="/work/cyber-monday-2021">Silver - Regional TV</a> |{" "}
              <a href="/work/red-desk">Silver - Online Series</a> |{" "}
              <a href="/work/your-goal-financed">
                Silver - General-Testimonial
              </a>{" "}
              | <a href="/work/stso">Silver - General-Testimonial</a> |{" "}
              <a href="/work/home-made-podcast">Bronze - Branded Podcast</a> |{" "}
              <a href="/work/big-change">Bronze - Online Series</a> |{" "}
              <a href="/work/home-lore">Bronze - Branded Content</a>
            </li>
            <li>
              <b>The D Show</b> :{" "}
              <a href="/work/home-made-podcast">
                Silver - Best of Audio Marketing
              </a>
            </li>
            <li>
              <b>Digiday Content Marketing Awards</b> :{" "}
              <a href="/work/home-made-podcast">
                Finalist - Best Branded Podcast
              </a>
            </li>
            <li>
              <b>New York Festivals Advertising Awards</b> :{" "}
              <a href="/work/home-made-podcast">
                Short List - Audio/Radio: Best Use Podcasts
              </a>
            </li>

            <li>
              <b>The Rock Honors</b>: Best Performance: Solution Focused | Best
              Strategic Execution
            </li>
            <li>
              <b>Apple Podcasts</b>:{" "}
              <a href="/work/home-made-podcast">New & Noteworthy</a>
            </li>

            <li>
              <b>AWWWards</b>: <a href="/work/preventure">Site of the Day</a>
            </li>
            <li>
              <b>United Nations</b> :{" "}
              <a href="/work/asass">Certificate of Appreciation</a>
            </li>
            {/* <li>
              {" "}
              <b>Shengzhou Travel Writer</b>: 1st
            </li>
            <li>
              <b>Through Foreign Eyes</b>:{" "}
              <a href="/work/photography"> 1st Photography</a> |
              <a href="/work/photography"> 2nd Photography</a>
            </li> */}
          </ul>
        </div>{" "}
      </div>
      {/* <div className={classes.body}>
        <b>PROJECT EXPERIENCE</b> Creative Direction & Strategy // Research
        Design & Implementation // Discourse and Corpus Analysis // Frontend
        Development // UI & UX Design // Brand Strategy // Radio & Audio
        production // SEO & Content Strategy
        <br />
        <b>PROGRAMMING EXPERIENCE</b> HTML // CSS // JavaScript // React // Java
        // Python // MERN
        <br />
      </div> */}
    </Fragment>
  );
};

export default About;
