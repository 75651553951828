import React, { useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import classes from "./Login.module.css";
import Header from "../Layout/Header";
import Helmet from "react-helmet";
import Box from "../Layout/Box";
import loglog from "../Assets/login.png";
import { login } from "../actions/users.js";

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [user, setUser] = useState({ email: "", password: "" });

  const [error, setError] = useState("");

  const changeHandler = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const loginHandler = (e) => {
    setError("Try again");
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    dispatch(login(user, history, setError));
  };
  const [loginfail, loginfailu] = useState("");

  return (
    <Fragment>
      <Helmet>
        <title>Login | Robbie Bailey</title>
      </Helmet>
      <Header />
      <div className={classes.holder}>
        <div className={classes.holderinner}>
          <Box backgroundColor="#F4F4F4">
            <div className={classes.logoboxouter}>
              <div className={classes.logobox}>
                <img className={classes.logicon} src={loglog} />
              </div>
              <div className={classes.logotitle}>Login</div>
            </div>

            <h2 className={classes.title}></h2>
            <div className={classes.logindeets}>
              <label></label>
              <div className={classes.input}>
                <input
                  name="email"
                  id="email"
                  value={user.email}
                  type="email"
                  placeholder=""
                  onChange={changeHandler}
                />
              </div>
              <label></label>
              <div className={classes.input}>
                <input
                  name="password"
                  id="password"
                  value={user.password}
                  type="password"
                  placeholder=""
                  onChange={changeHandler}
                />
              </div>

              <div className={classes.space} />
              <center>
                {loginfail}
                {error && <span className={classes.error}>{error}</span>}
                <button className={classes.cta} onClick={loginHandler}>
                  Login
                </button>
              </center>
            </div>
          </Box>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
